import { createContext, useState } from 'react';

export const CommonContext = createContext({});

/**
 * Common context to be used for all common features required throughout the website
 * @param {*} param0
 * @author Anshu Singh <anshu17singh@gmail.com>
 */
export default function CommonProvider({ children }) {
  const [isConfirmBox, toggleConfirmBox] = useState(false);
  const [confirmTitle, setTitle] = useState('');
  const [confirmText, setText] = useState('');
  const [confirmOk, setOkText] = useState('');
  const [successFn, setSuccessFn] = useState(null);

  const openConfirmBox = (title, text, okText, cb) => {
    setTitle(title);
    setText(text);
    setOkText(okText);
    setSuccessFn(() => cb);
    toggleConfirmBox(true);
  };

  const closeConfirmBox = () => {
    toggleConfirmBox(false);
  };

  return (
    <CommonContext.Provider
      value={{
        openConfirmBox,
        closeConfirmBox,
        isConfirmBox,
        confirmTitle,
        confirmText,
        confirmOk,
        successFn,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
}
