import { createContext, useState } from 'react';
import AddRating from '@/containers/OrderDetails/AddRating';

export const OrderContext = createContext({});

export default function OrderRatingProvider({ children }) {
  const DEFAULT_PROPS = {
    loading: false,
    customer: {},
    onSubmit: () => {},
  };

  const [isVisible, setVisibility] = useState(false);
  const [props, setProps] = useState(DEFAULT_PROPS);

  const closeOrderRating = () => setVisibility(false);

  const openOrderRating = (providedProps) => {
    setProps({
      ...DEFAULT_PROPS,
      onClose: closeOrderRating,
      ...providedProps,
    });
    setVisibility(true);
  };

  return (
    <OrderContext.Provider value={{ openOrderRating, closeOrderRating }}>
      {isVisible && <AddRating {...props} />}
      {children}
    </OrderContext.Provider>
  );
}
