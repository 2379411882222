// Core Packages
import { useState } from 'react';

// Custom Components
import Dialog from '@/components/Dialog';
import StyledRating from '@/components/Rating';
import StyledButton from '@/components/Button';
import Textarea from '@/components/Textarea';

// Utilities
import { useTranslation } from 'react-i18next';

// Styles
import styles from './style.module.css';

export default function AddRating({
  onClose, customer, onSubmit, loading,
}) {
  const { t: translate } = useTranslation();
  const [form, setForm] = useState({
    driverRating: 0,
    salesRepresentativeRating: 0,
    inventoryOfficerRating: 0,
    feedback: '',
  });
  const {
    driverRating,
    salesRepresentativeRating,
    inventoryOfficerRating,
    feedback,
  } = form;
  const updateForm = (key) => (value) => setForm({ ...form, [key]: value });
  const submitForm = () => onSubmit(form);

  return (
    <Dialog onClose={onClose} containerClassName={styles.ratingDialog}>
      <h4 className={styles.ratingTitle}>{translate('Add Rating')}</h4>
      <h4 className={styles.customerName}>{customer.Stores[0].contactName}</h4>
      <p className={styles.info}>
        {translate('Your rating will improve our delivery experience')}
      </p>
      <h4 className={styles.role}>{translate('Driver Rating')}</h4>
      <StyledRating
        className={styles.rating}
        name="driverRating"
        onChange={updateForm('driverRating')}
        value={driverRating}
        count={5}
      />
      <h4 className={styles.role}>
        {translate('Sales Representative Rating')}
      </h4>
      <StyledRating
        className={styles.rating}
        name="salesRepresentativeRating"
        onChange={updateForm('salesRepresentativeRating')}
        value={salesRepresentativeRating}
        count={5}
      />
      <h4 className={styles.role}>{translate('Inventory Officer')}</h4>
      <StyledRating
        className={styles.rating}
        name="inventoryOfficerRating"
        onChange={updateForm('inventoryOfficerRating')}
        value={inventoryOfficerRating}
        count={5}
      />
      <Textarea
        className={styles.textarea}
        label={translate('Feedback')}
        placeholder={translate('Enter Feedback')}
        value={feedback}
        maxLength={300}
        onChange={updateForm('feedback')}
        required
      />
      <StyledButton
        type="button"
        disabled={
          !feedback
          || loading
          || !inventoryOfficerRating
          || !driverRating
          || !salesRepresentativeRating
        }
        onClick={submitForm}
        className={styles.actionButton}
      >
        {translate('Submit')}
      </StyledButton>
    </Dialog>
  );
}
