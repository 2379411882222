import Rating from '@material-ui/lab/Rating';

export default function StyledRating({
  className,
  name = 'rating',
  value,
  count = 5,
  onChange,
  disabled = false,
}) {
  const changeHandler = (event, newValue) => onChange(newValue || value);

  return (
    <Rating
      className={className}
      name={name}
      value={value}
      max={count}
      disabled={disabled}
      onChange={changeHandler}
    />
  );
}
