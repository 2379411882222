import { gql } from '@apollo/client';

export const GET_CART_ITEMS = gql`
  query GET_CUSTOMER_CART($warehouseId: String) {
    getCustomerCart(warehouseId: $warehouseId) {
      customer {
        customerId
        businessName
        agentName
        phoneNo
        additionalPhones
        Stores {
          typeOfStore
          address
          contactName
          long
          lat
          city
          landmark
          landmarkId
        }
      }
      cart {
        productId
        sellerId
        sellerName
        product {
          _id
          vertical
          verticalId
          brand
          category
          subCategory
          brand
          product_SKU
          variant
          image {
            original
            medium
            small
            large
          }
          images {
            imageUrl
          }
          pricing {
            wholesalePrice
            retailPrice
          }
          discountPricing {
            wholesalePrice
            retailPrice
          }
          cityWisePricing {
            minimumQuantity
            maximumQuantityPerOrder
            pricing {
              wholesalePrice
              retailPrice
            }
          }
          promotion {
            promotionName
            discount
            discountType
          }
          sellerId
          warehouse

          minWholeSaleQty
          order_qty_max_limit
          bundle {
            _id
            sellerId
            bundleName
            minimumQuantity
            bundleType
            maxDiscountedQty
            discountType
            discount
            mandatory
            productType
            __typename @skip(if: true)
          }
          stockValue
          productMainId
          __typename @skip(if: true)
        }
        quantity
      }
    }
  }
`;

export const UPDATE_CART_ITEMS = gql`
  mutation UPDATE_CART_ITEMS(
    $items: [CartItemInput]
    $warehouseId: String
    $verticalId: String
    $vertical: String
  ) {
    updateCart(
      items: $items
      warehouseId: $warehouseId
      verticalId: $verticalId
      vertical: $vertical
    ) {
      _id
      productId
      sellerId
      sellerName
      quantity
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UPDATE_CUSTOMER(
    $customerId: String!
    $phoneNo: String
    $businessName: String
    $contactName: String
    $typeOfStore: String
    $address: String
    $landmark: String
    $landmarkId: String
    $customerContacts: [CustomerContactInput]
    $email: String
    $city: String!
    $cityId: String!
    $lat: Float
    $long: Float
  ) {
    updateCustomer(
      customerId: $customerId
      phoneNo: $phoneNo
      customerContacts: $customerContacts
      businessName: $businessName
      contactName: $contactName
      typeOfStore: $typeOfStore
      address: $address
      landmark: $landmark
      landmarkId: $landmarkId
      email: $email
      city: $city
      cityId: $cityId
      lat: $lat
      long: $long
    ) {
      customerId
    }
  }
`;

export const GET_WAREHOUSE = gql`
  query GET_WAREHOUSE($warehouseId: String) {
    getWarehouse(warehouseId: $warehouseId) {
      warehouseId
      warehouseName
      Landmarks {
        landmarkId
        landmarkName
      }
      City {
        cityId
        cityName
        State {
          stateId
          stateName
        }
      }
    }
  }
`;

export const GET_DEFAULT_WAREHOUSE = gql`
  query GET_DEFAULT_WAREHOUSE {
    getDefaultWarehouse {
      cityId
      stateId
      stateName
      cityName
      defaultWarehouseId
      landmarks {
        landmarkId
        warehouseId
        landmarkName
        lat
        long
        isActive
      }
    }
  }
`;

export const CUSTOMER_CHECKOUT = gql`
  mutation CUSTOMER_CHECKOUT(
    $warehouseId: String!
    $platform: String!
    $app_version: String
    $couponCode: String
    $isPrePaid: Boolean
    $bnplIntended: Boolean
    $verticalId: String
    $preferredDeliveryDate: String
  ) {
    customerCheckout(
      warehouseId: $warehouseId
      platform: $platform
      app_version: $app_version
      couponCode: $couponCode
      bnplIntended: $bnplIntended
      verticalId: $verticalId
      isPrePaid: $isPrePaid
      preferredDeliveryDate: $preferredDeliveryDate
    ) {
      orders {
        _id
        referenceId
        displayStatus
        totalItemQuantity
        orderTotal
        isPaid
        orderItems {
          _id
        }
        statusLabel
        createdAt
        rating {
          driverRating
          salesReperesentativeRating
          inventoryOfficerRating
        }
        customer {
          CustomerContacts {
            firstName
            phoneNo
            email
          }
          Stores {
            contactName
          }
        }
      }
      message
    }
  }
`;

export const REQUEST_NOTIFICATION = gql`
  mutation REQUEST_NOTIFICATION(
    $customerId: String!
    $productId: String!
    $warehouseId: String!
    $sellerId: String!
  ) {
    notifyCustomerDetails(
      warehouseId: $warehouseId
      customerId: $customerId
      productId: $productId
      sellerId: $sellerId
    ) {
      status
    }
  }
`;

export const GET_NOTIFIED_PRODUCTS = gql`
  query GET_NOTIFIED_PRODUCTS($customerId: String!) {
    getNotifiedProductsByCustomerId(customerId: $customerId) {
      customerId
      productId
      warehouseId
      status
      createdAt
      updatedAt
    }
  }
`;

export const GET_MIN_ORDER_AMOUNT = gql`
  query GET_MIN_ORDER_AMOUNT(
    $cityId: String
    $warehouseId: String
    $stateId: String
  ) {
    getMinimumOrderAmountByCity(
      cityId: $cityId
      warehouseId: $warehouseId
      stateId: $stateId
    )
  }
`;

export const CHECK_COUPON_VALIDITY = gql`
  query CHECK_COUPON_VALIDITY($couponCode: String!, $orderTotal: Float!) {
    checkCouponValidity(couponCode: $couponCode, orderTotal: $orderTotal) {
      valid
      discount
      discountType
      orderTotal
      isReferCode
      coupon {
        couponName
        couponCode
        couponDescription
      }
    }
  }
`;

export const GET_COUPON = gql`
  query CHECK_COUPON_VALIDITY($couponCode: String!) {
    getCouponCode(couponCode: $couponCode) {
      couponDescription
    }
  }
`;

export const GET_CREDIT_LIMIT = gql`
  query GET_CREDIT_LIMIT($customerId: String) {
    getCreditLimit(customerId: $customerId) {
      availableCreditLimit
      totalCreditLimit
      __typename
    }
  }
`;

export const GET_CART_INFO = gql`
  query GET_CART_INFO($verticalId: String) {
    getCustomerCartInfo(verticalId: $verticalId) {
      verticalId
      vertical
      cartQuantity
    }
  }
`;
