// Core Packages
import classnames from 'classnames';

// Material
import Button from '@material-ui/core/Button';

// Styles
import styles from './style.module.css';

export default function StyledButton({
  type = 'button',
  className,
  variant = 'contained',
  disabled = false,
  onClick,
  children = null,
  color = 'primary',
  id,
}) {
  const buttonClasses = classnames(styles.button, className);

  return (
    <Button
      data-test="component-styled-button"
      className={buttonClasses}
      color={color}
      type={type}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      id={id}
    >
      {children}
    </Button>
  );
}
