// Core Packages
import classnames from 'classnames';

// Styles
import styles from './style.module.css';

export default function StyledLabel({
  label,
  htmlFor,
  className = '',
  required = false,
}) {
  const labelClasses = classnames('row j-start a-center', styles.label, className);

  return (
    <label className={labelClasses} htmlFor={htmlFor}>
      {label}
      {required && <sup>*</sup>}
    </label>
  );
}
