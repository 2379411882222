import Link from 'next/link';
import { useEffect } from 'react';
import ReactCookieConsent from 'react-cookie-consent';
import styles from './styles.module.css';

function CookieConsent() {
  // function for checking whether visitor has consented before
  function checkConsented() {
    let decodedCookie = decodeURIComponent(document.cookie);
    decodedCookie = decodedCookie.split(';');
    decodedCookie = decodedCookie.find(
      (cookie) => cookie.substring(0, 13) === 'CookieConsent',
    );
    if (!decodedCookie) {
      return false;
    }
    if (decodedCookie.substring(14, 18) === 'true') {
      return true;
    }
    return false;
  }

  // for subsequent page visits
  useEffect(() => {
    if (checkConsented()) {
      window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
      });
    }
  }, []);

  function handleAccept() {
    window.gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
  }

  return (
    <ReactCookieConsent
      buttonText="Accept"
      style={{
        background: '#F3F3F7',
        fontFamily: 'Gilmer,sans-serif',
        borderTopRightRadius: '24px',
        borderTopLeftRadius: '24px',
        // maxWidth: '868px',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
      overlayClasses={styles.overlay}
      contentStyle={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: '40px',
        paddingBottom: '80px',
      }}
      buttonWrapperClasses={styles.buttonContainer}
      buttonStyle={{
        backgroundColor: '#1A51A3',
        fontWeight: 500,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '122px',
        height: '60px',
        boxShadow: '0px 1px 2px 0px #1018280D',
        borderRadius: '10px',
        color: 'white',
        margin: 0,
      }}
      expires={150}
      acceptOnScroll={false}
      enableDeclineButton
      declineButtonText="Decline"
      declineButtonClasses={styles.declineButton}
      buttonClasses={styles.acceptButton}
      onAccept={() => (acceptedByScrolling) => {
        if (acceptedByScrolling) {
          // triggered if user scrolls past threshold
          handleAccept();
        } else {
          handleAccept();
        }
      }}
    >
      <div className={styles.textContainer}>
        <p className={styles.title}>Cookies Notice</p>
        <span className={styles.description}>
          We use cookies to optimize your experience and enhance our marketing efforts.
          All information collected adheres to our data privacy obligations. Check our
          {' '}
          <Link href="/privacy" passHref>
            <span className={styles.link}>Privacy Policy</span>
          </Link>
          {' '}
          here.
        </span>
      </div>
    </ReactCookieConsent>
  );
}

export default CookieConsent;
