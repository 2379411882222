// Core
import { useEffect, useState } from 'react';
import Link from 'next/link';
import classnames from 'classnames';

// Styles
import styles from './style.module.css';

export const ChevronRightIcon = ({ className, fill = '' }) => (
  <svg
    className={className}
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.29006 0.710022C0.900059 1.10002 0.900059 1.73002 1.29006 2.12002L5.17006 6.00002L1.29006 9.88002C0.900059 10.27 0.900059 10.9 1.29006 11.29C1.68006 11.68 2.31006 11.68 2.70006 11.29L7.29006 6.70002C7.68006 6.31002 7.68006 5.68002 7.29006 5.29002L2.70006 0.700022C2.32006 0.320022 1.68006 0.320022 1.29006 0.710022Z"
      fill={fill || 'white'}
    />
  </svg>
);

export default function Notification({
  text,
  link,
  duration = 3000,
  visible,
  setVisible,
  isError,
  variant,
}) {
  const [timeoutId, setTimeoutId] = useState(null);
  const [currentText, setCurrentText] = useState('');
  // const [visible, setVisible] = useState(false);

  const containerClasses = isError
    ? classnames(
      styles.container,
      styles.containerError,
      'row j-space-between a-center',
      variant === 'topCenter' ? styles.topCenter : '',
    )
    : classnames(
      styles.container,
      'row j-space-between a-center',
      variant === 'topCenter' ? styles.topCenter : '',
    );

  useEffect(() => {
    if (visible && text) {
      setCurrentText(text);

      setTimeoutId(
        setTimeout(() => {
          setVisible(false);
        }, duration),
      );
    } else if (visible && !timeoutId) {
      setTimeoutId(
        setTimeout(() => {
          setVisible(false);
        }, duration),
      );
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [visible, text]);

  return (
    visible && (
      <div className={containerClasses}>
        {link ? (
          <Link href={link}>
            <div className="row a-center">
              <span className={styles.btnLabel}>{text}</span>
              <ChevronRightIcon className={styles.icon} />
            </div>
          </Link>
        ) : (
          <div className="row a-center">
            <span className={styles.btnLabel}>{currentText}</span>
          </div>
        )}
      </div>
    )
  );
}
