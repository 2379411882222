import firebase from '../../firebase';

export const setProperties = (userId, businessName, phoneNumber) => {
  const analytics = firebase.analytics();

  analytics.setUserId(userId);
  analytics.setUserProperties({
    businessName,
    phoneNumber,
    platform: 'ALERZOSHOP_PWA',
  });
};

export const resetProperties = () => {
  const analytics = firebase.analytics();

  analytics.setUserId('N/A');
  analytics.setUserProperties({
    businessName: 'N/A',
    phoneNumber: 'N/A',
    platform: 'ALERZOSHOP_PWA',
  });
};

export const viewCategory = (
  category,
  warehouseId,
  sortBy,
  sortOrder,
  customerId,
) => {
  const analytics = firebase.analytics();

  analytics.logEvent('view_category', {
    category_name: category,
    warehouse_id: warehouseId,
    sort_by: sortBy,
    sort_order: sortOrder,
    customerId,
  });
};

export const viewProductListing = (type, warehouseId, customerId) => {
  const analytics = firebase.analytics();

  analytics.logEvent('view_product_listing', {
    listing_type: type,
    warehouse_id: warehouseId,
    customerId,
  });
};

export const createCustomer = (customerId) => {
  const analytics = firebase.analytics();

  analytics.logEvent('create_customer', {
    customer_identifier: customerId,
  });
};
export const logoutCustomer = (customerId) => {
  const analytics = firebase.analytics();

  analytics.logEvent('logout', {
    customer_identifier: customerId,
  });
};

export const viewCart = (totalItems, grossTotal, totalPrice, customerId) => {
  const analytics = firebase.analytics();

  analytics.logEvent('view_cart', {
    number_of_cart_items: totalItems,
    gross_total: grossTotal,
    total_price: totalPrice,
    customerId,
  });
};

export const addToCart = (
  id,
  variant,
  category,
  brand,
  quantity,
  customerId,
) => {
  const analytics = firebase.analytics();

  analytics.logEvent('add_to_cart', {
    product_id: id,
    variant,
    category,
    brand,
    quantity,
    customerId,
  });
};
export const viewItem = (id, variant, category, brand, customerId) => {
  const analytics = firebase.analytics();

  analytics.logEvent('view_item', {
    product_id: id,
    variant,
    category,
    brand,
    customerId,
  });
};
export const notifyProduct = (id, warehouseId, customerId) => {
  const analytics = firebase.analytics();

  analytics.logEvent('notify_product', {
    product_id: id,
    warehouse_Id: warehouseId,
    customerId,
  });
};

export const similarItems = (id, warehouseId, customerId) => {
  const analytics = firebase.analytics();
  analytics.logEvent('view_similar_items', {
    product_id: id,
    warehouse_Id: warehouseId,
    customerId,
  });
};

export const reorderIntended = (
  id,
  variant,
  category,
  brand,
  quantity,
  sellerId,
  sellerName,
  source,
  customerId,
) => {
  const analytics = firebase.analytics();

  analytics.logEvent('reorder_Intended', {
    product_id: id,
    variant,
    category,
    brand,
    quantity,
    sellerId,
    sellerName,
    source,
    customerId,
  });
};
export const removeFromCart = (
  id,
  variant,
  category,
  brand,
  quantity,
  customerId,
) => {
  const analytics = firebase.analytics();

  analytics.logEvent('remove_from_cart', {
    product_id: id,
    variant,
    category,
    brand,
    quantity,
    customerId,
  });
};

export const initiateCheckout = (
  totalItems,
  grossTotal,
  totalPrice,
  customerId,
) => {
  const analytics = firebase.analytics();

  analytics.logEvent('begin_checkout', {
    number_of_cart_items: totalItems,
    gross_total: grossTotal,
    total_price: totalPrice,
    customerId,
  });
};

export const reviewPayment = (
  totalItems,
  grossTotal,
  totalPrice,
  customerId,
) => {
  const analytics = firebase.analytics();

  analytics.logEvent('review_payment', {
    number_of_cart_items: totalItems,
    gross_total: grossTotal,
    total_price: totalPrice,
    customerId,
  });
};

export const placedCustomerOrder = (
  id,
  totalItems,
  grossTotal,
  totalPrice,
  customerId,
) => {
  const analytics = firebase.analytics();

  analytics.logEvent('purchase', {
    reference_id: id,
    number_of_cart_items: totalItems,
    gross_total: grossTotal,
    total_price: totalPrice,
    customerId,
  });
};

export const failOrder = (totalItems, grossTotal, totalPrice, customerId) => {
  const analytics = firebase.analytics();

  analytics.logEvent('failed_order', {
    number_of_cart_items: totalItems,
    gross_total: grossTotal,
    total_price: totalPrice,
    customerId,
  });
};

export const searchTerm = (
  term,
  warehouseId,
  sortBy,
  sortOrder,
  customerId,
) => {
  const analytics = firebase.analytics();

  analytics.logEvent('search_term', {
    search_term: term,
    warehouse_id: warehouseId,
    sort_by: sortBy,
    sort_order: sortOrder,
    customerId,
  });
};

export const clickBanner = (
  imageUrl,
  redirectTo,
  redirectValue,
  customerId,
) => {
  const analytics = firebase.analytics();

  analytics.logEvent('click_banner', {
    image_url: imageUrl,
    redirectTo,
    redirectValue,
    customerId,
  });
};

export const productSort = (sortBy, sortOrder, customerId) => {
  const analytics = firebase.analytics();

  analytics.logEvent('product_sort', {
    sort_by: sortBy,
    sort_order: sortOrder,
    customerId,
  });
};

export const productFilter = (
  category,
  subCategory,
  brand,
  priceRange,
  outOfStock,
  customerId,
) => {
  const analytics = firebase.analytics();

  analytics.logEvent('product_filter', {
    category,
    subCategory,
    brand,
    price_range: priceRange,
    include_out_of_stock: outOfStock,
    customerId,
  });
};

export const pwaInstalled = (customerId) => {
  const analytics = firebase.analytics();
  analytics.logEvent('pwa_installed', {
    customerId,
  });
};

export const changeLanguage = (preferredLanguage, customerId) => {
  const analytics = firebase.analytics();
  analytics.logEvent('change_language', {
    preferredLanguage,
    customerId,
  });
};
export const notificationReceived = (title, body, customerId) => {
  const analytics = firebase.analytics();
  analytics.logEvent('notification_received', {
    title,
    body,
    customerId,
  });
};

// export const notificationBackground = (title, body, customerId) => {
//   const analytics = firebase.analytics();
//   analytics.logEvent('notification_background', {
//     title,
//     body,
//     customerId,
//   });
// };
