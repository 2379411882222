/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_CANCELLATION_REASONS = gql`
  query GET_CANCELLATION_REASONS {
    getReasons {
      _id
      reason
      platform
      status
      isActive
      userId
      userName
      createdAt
      updatedAt
    }
  }
`;

export const GET_APP_INFORMATION = gql`
  query GET_APP_INFORMATION {
    getAppInformation {
      minVersion
      latestVersion
      message
      playstoreUrl
      appstoreUrl
      isOauthEnabled
      oauthUrl
      ouathUrlNew
    }
  }
`;

export const SUBMIT_QUERY = gql`
  mutation SUBMIT_QUERY(
    $fullName: String
    $phoneNumber: String
    $reason: String!
    $message: String!
  ) {
    submitContactForm(
      fullName: $fullName
      phoneNumber: $phoneNumber
      reason: $reason
      message: $message
    ) {
      success
    }
  }
`;

export const GET_NEAREST_WAREHOUSE = gql`
  query GET_NEAREST_WAREHOUSE($lat: Float, $long: Float) {
    getNearestWarehouse(latitude: $lat, longitude: $long) {
      warehouseId
      lat
      long
      City {
        cityId
        cityName
        State {
          stateId
          stateName
        }
      }
      warehouseName
      distance
      isActive
    }
  }
`;

export const GET_CUSTOMER_META = gql`
  query GET_CUSTOMER_META($customerId: String) {
    getCustomerMeta(customerId: $customerId) {
      customerId
      preferredLanguage
      vertical
    }
  }
`;

export const UPDATE_CUSTOMER_META = gql`
  mutation UPDATE_CUSTOMER_META(
    $customerId: String
    $preferredLanguage: PreferredLanguage
    $vertical: String
  ) {
    updateCustomerMeta(
      customerId: $customerId
      preferredLanguage: $preferredLanguage
      vertical: $vertical
    ) {
      success
    }
  }
`;
