import { gql } from '@apollo/client';

export const GET_PRODUCTS_LIST = gql`
  query GET_PRODUCTS_LIST(
    $searchQuery: String
    $sortOrder: String
    $sortBy: String
    $size: Int
    $city: String
    $warehouse: String
    $filters: ProductFilter
    $platform: String!
    $outOfStock: Boolean
    $ignoreScore: Boolean
  ) {
    getProducts(
      searchQuery: $searchQuery
      productSortOrder: $sortOrder
      sortBy: $sortBy
      size: $size
      warehouse: $warehouse
      city: $city
      filters: $filters
      platform: $platform
      outOfStock: $outOfStock
      ignoreScore: $ignoreScore
    ) {
      nodes {
        _id
        brand
        category
        subCategory
        brand
        product_SKU
        variant
        image {
          original
          medium
          small
          large
        }
        verticalId
        vertical
        images {
          imageUrl
        }
        pricing {
          wholesalePrice
          retailPrice
        }
        productMainId
        stockValue
        minWholeSaleQty
        bundle {
          _id
          sellerId
          bundleName
          minimumQuantity
          bundleType
          maxDiscountedQty
          discountType
          discount
          mandatory
          productType
          __typename @skip(if: true)
        }
        discountPricing {
          wholesalePrice
          retailPrice
        }
        cityWisePricing {
          minimumQuantity
          maximumQuantityPerOrder
          pricing {
            wholesalePrice
            retailPrice
          }
        }
        promotion {
          promotionName
          discount
          discountType
        }
        sellerId
        warehouse
        order_qty_max_limit
      }
    }
  }
`;

export const GET_PRODUCTS_LIST_WITH_SORT = gql`
  query GET_PRODUCTS_LIST_WITH_SORT(
    $searchQuery: String
    $sortOrder: String
    $sortBy: String
    $size: Int!
    $platform: String!
    $warehouse: String
  ) {
    getProducts(
      searchQuery: $searchQuery
      productSortOrder: $sortOrder
      sortBy: $sortBy
      size: $size
      platform: $platform
      warehouse: $warehouse
    ) {
      nodes {
        _id
        brand
        category
        subCategory
        brand
        product_SKU
        variant
        image {
          original
          medium
          small
          large
        }
        verticalId
        vertical
        images {
          imageUrl
        }
        pricing {
          wholesalePrice
          retailPrice
        }
        minWholeSaleQty
        discountPricing {
          wholesalePrice
          retailPrice
        }
        productMainId
        stockValue
        cityWisePricing {
          minimumQuantity
          maximumQuantityPerOrder
          pricing {
            wholesalePrice
            retailPrice
          }
        }
        promotion {
          promotionName
          discount
          discountType
        }
        sellerId
        warehouse
        order_qty_max_limit
        bundle {
          _id
          sellerId
          bundleName
          minimumQuantity
          bundleType
          maxDiscountedQty
          discountType
          discount
          mandatory
          productType
          __typename @skip(if: true)
        }
      }
    }
  }
`;

export const SEARCH_PRODUCTS = gql`
  query SEARCH_PRODUCTS(
    $searchQuery: String
    $sortBy: String
    $platform: String!
    $warehouse: String
    $cityId: String
    $filters: ProductFilter
  ) {
    getProducts(
      searchQuery: $searchQuery
      sortBy: $sortBy
      platform: $platform
      warehouse: $warehouse
      city: $cityId
      filters: $filters
    ) {
      nodes {
        _id
        variant
        brand
        category
        subCategory
        vertical
      }
    }
  }
`;

export const GET_PRODUCT_FILTERS = gql`
  query GET_PRODUCT_FILTERS(
    $categories: [String]
    $subCategories: [String]
    $brands: [String]
    $verticals: [String]
  ) {
    getProductFiltersApp(
      categories: $categories
      subCategories: $subCategories
      brands: $brands
      verticals: $verticals
    ) {
      categories {
        key
      }
      subCategories {
        key
      }
      brands {
        key
      }
      products {
        key
      }
      verticals {
        key
      }
    }
  }
`;

export const GET_PRODUCT_CATEGORIES = gql`
  query GET_PRODUCT_CATEGORIES($stateId: String, $cityId: String) {
    fetchProductCategory(stateId: $stateId, cityId: $cityId) {
      sequenceNo
      searchTerms
      categoryName
      icon
    }
  }
`;

export const GET_VERTICALS = gql`
  query GET_VERTICALS(
    $page: Int
    $size: Int
    $sortOrder: String
    $sortBy: String
    $query: String
    $populateAll: Boolean
    $populateSubcategories: Boolean!
  ) {
    fetchVerticals(
      page: $page
      size: $size
      sortOrder: $sortOrder
      sortBy: $sortBy
      query: $query
      populateAll: $populateAll
    ) {
      nodes {
        _id
        thumbnailURL
        sequenceNo
        verticalName
        paymentMethods
        categories {
          _id
          category_name
          categoryPrefix
          thumbnailURL
          isActive
          sequenceNo
          searchTerms
          excludeStates
          excludeCities
          platform
          subcategories @include(if: $populateSubcategories) {
            _id
            subCategoryName
            thumbnailURL
            category {
              _id
            }
            subCategoryPrefix
            isActive
          }
          verticalId
          vertical
        }
      }
    }
  }
`;

export const GET_PRODUCT_DETAILS = gql`
  query GET_PRODUCT_DETAILS(
    $_id: String!
    $platform: String!
    $warehouse: String
  ) {
    getProduct(_id: $_id, platform: $platform, warehouse: $warehouse) {
      _id
      category
      subCategory
      brand
      product_SKU
      variant
      description
      verticalId
      vertical
      image {
        original
        medium
        small
        large
      }
      images {
        imageUrl
      }
      otherImages
      otherOptimisedImages {
        small
        medium
        large
        original
      }
      pricing {
        wholesalePrice
        retailPrice
      }
      productMainId
      stockValue
      minWholeSaleQty
      bundle {
        _id
        sellerId
        bundleName
        minimumQuantity
        bundleType
        maxDiscountedQty
        discountType
        discount
        mandatory
        productType
        __typename @skip(if: true)
      }
      discountPricing {
        wholesalePrice
        retailPrice
      }
      cityWisePricing {
        minimumQuantity
        maximumQuantityPerOrder
        pricing {
          wholesalePrice
          retailPrice
        }
      }
      promotion {
        promotionName
        discount
        discountType
      }
      sellerId
      warehouse
      order_qty_max_limit
      attributes {
        attributeName
        attributeValue
        _id
        __typename
      }
    }
  }
`;

export const GET_SIMILAR_PRODUCTS = gql`
  query GET_SIMILAR_PRODUCTS(
    $warehouseId: String
    $productId: String
    $verticals: [String]
  ) {
    getSimilarProduct(
      warehouseId: $warehouseId
      productId: $productId
      verticals: $verticals
    ) {
      nodes {
        _id
        brand
        category
        subCategory
        brand
        product_SKU
        variant
        image {
          original
          medium
          small
          large
        }
        verticalId
        vertical
        images {
          imageUrl
        }
        pricing {
          wholesalePrice
          retailPrice
        }
        stockValue
        minWholeSaleQty
        discountPricing {
          wholesalePrice
          retailPrice
        }
        cityWisePricing {
          minimumQuantity
          maximumQuantityPerOrder
          pricing {
            wholesalePrice
            retailPrice
          }
        }
        promotion {
          promotionName
          discount
          discountType
        }
        sellerId
        warehouse
        order_qty_max_limit
      }
    }
  }
`;
