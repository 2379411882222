export const CategoriesUnselectedIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="3" width="10.4167" height="10.4167" rx="2" fill="#8991AC" />
    <rect
      x="17.5835"
      y="3"
      width="10.4167"
      height="10.4167"
      rx="5.20833"
      fill="#8991AC"
    />
    <rect
      x="3"
      y="17.5833"
      width="10.4167"
      height="10.4167"
      rx="2"
      fill="#D2D9E3"
    />
    <rect
      x="17.5835"
      y="17.5833"
      width="10.4167"
      height="10.4167"
      rx="2"
      fill="#8991AC"
    />
  </svg>
);

export const CategoriesIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="3" width="10.4167" height="10.4167" rx="2" fill="#1A51A3" />
    <rect
      x="17.5835"
      y="3"
      width="10.4167"
      height="10.4167"
      rx="5.20833"
      fill="#1A51A3"
    />
    <rect
      x="3"
      y="17.5833"
      width="10.4167"
      height="10.4167"
      rx="2"
      fill="#00954B"
    />
    <rect
      x="17.5835"
      y="17.5833"
      width="10.4167"
      height="10.4167"
      rx="2"
      fill="#1A51A3"
    />
  </svg>
);

export const SelectedAlerzoIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.8921 14.412C26.8917 16.1909 26.4342 17.9399 25.5633 19.4921C24.6924 21.0442 23.4371 22.3476 21.9175 23.2778V5.55054C23.4362 6.48061 24.6909 7.78341 25.5617 9.33468C26.4326 10.886 26.8906 12.634 26.8921 14.412Z"
      fill="#00954B"
    />
    <path
      d="M26.8921 14.4121V23.9592C26.8921 24.1858 26.8019 24.4031 26.6413 24.5633C26.4807 24.7235 26.263 24.8135 26.0359 24.8135H22.7908C22.5637 24.8135 22.3459 24.7235 22.1854 24.5633C22.0248 24.4031 21.9346 24.1858 21.9346 23.9592V23.2779C23.4511 22.3456 24.7029 21.0413 25.5708 19.4893C26.4386 17.9373 26.8935 16.1893 26.8921 14.4121Z"
      fill="#FFB207"
    />
    <path
      d="M29.1436 4H26.1169H26.0741H26.0227H22.7776C22.5505 4 22.3327 4.09001 22.1722 4.25023C22.0116 4.41044 21.9214 4.62774 21.9214 4.85432V5.53779C23.4405 6.46805 24.6953 7.77118 25.5662 9.32285C26.437 10.8745 26.8949 12.623 26.896 14.4014V9.82862C26.896 9.60204 26.9862 9.38474 27.1468 9.22452C27.3074 9.06431 27.5252 8.9743 27.7522 8.9743H29.1436C29.3707 8.9743 29.5885 8.88429 29.749 8.72407C29.9096 8.56386 29.9998 8.34656 29.9998 8.11997V4.86714C30.0015 4.75387 29.9806 4.64141 29.9384 4.53627C29.8961 4.43114 29.8333 4.33543 29.7536 4.25474C29.674 4.17404 29.579 4.10997 29.4743 4.06624C29.3695 4.0225 29.2571 3.99999 29.1436 4Z"
      fill="#FFB207"
    />
    <path
      d="M24.3408 31.1227C25.6802 31.1227 26.766 30.0393 26.766 28.7028C26.766 27.3664 25.6802 26.283 24.3408 26.283C23.0013 26.283 21.9155 27.3664 21.9155 28.7028C21.9155 30.0393 23.0013 31.1227 24.3408 31.1227Z"
      fill="#1A51A3"
    />
    <path
      d="M8.44673 31.1227C9.78616 31.1227 10.872 30.0393 10.872 28.7028C10.872 27.3664 9.78616 26.283 8.44673 26.283C7.1073 26.283 6.02148 27.3664 6.02148 28.7028C6.02148 30.0393 7.1073 31.1227 8.44673 31.1227Z"
      fill="#1A51A3"
    />
    <path
      d="M16.4416 4.00073C15.0044 4.0025 13.5829 4.30019 12.2663 4.87517C10.9496 5.45015 9.76604 6.29004 8.78959 7.34232C7.81313 8.39459 7.06482 9.6366 6.59145 10.9907C6.11808 12.3447 5.92984 13.7816 6.03852 15.2116C6.03852 15.2458 6.03852 15.2778 6.03852 15.3099H6V23.9471C6 24.1737 6.09019 24.391 6.25077 24.5512C6.41134 24.7114 6.62914 24.8014 6.85622 24.8014H15.7566C17.9079 24.9409 20.0493 24.4079 21.8829 23.2764V14.4128C21.8833 15.4906 21.5634 16.5443 20.9635 17.4406C20.3637 18.3369 19.5109 19.0356 18.513 19.4482C17.5151 19.8609 16.417 19.969 15.3576 19.7588C14.2981 19.5487 13.325 19.0297 12.5612 18.2676C11.7974 17.5055 11.2772 16.5345 11.0666 15.4774C10.856 14.4203 10.9643 13.3246 11.3779 12.3289C11.7914 11.3333 12.4916 10.4824 13.3899 9.88383C14.2883 9.28531 15.3443 8.96607 16.4245 8.96649C17.8716 8.96819 19.259 9.54254 20.2823 10.5636C21.3056 11.5846 21.8812 12.9689 21.8829 14.4128V5.5492C20.2486 4.53854 18.3644 4.00233 16.4416 4.00073Z"
      fill="#1A51A3"
    />
  </svg>
);

export const UnselectedAlerzoIcon = ({ className, size = '32' }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.8921 14.4119C26.8917 16.1908 26.4342 17.9398 25.5633 19.492C24.6924 21.0441 23.4371 22.3475 21.9175 23.2777V5.55045C23.4362 6.48052 24.6909 7.78332 25.5617 9.33459C26.4326 10.8859 26.8906 12.6339 26.8921 14.4119Z"
      fill="#69778C"
    />
    <path
      d="M26.8921 14.4122V23.9592C26.8921 24.1858 26.8019 24.4031 26.6413 24.5633C26.4807 24.7236 26.263 24.8136 26.0359 24.8136H22.7908C22.5637 24.8136 22.3459 24.7236 22.1854 24.5633C22.0248 24.4031 21.9346 24.1858 21.9346 23.9592V23.2779C23.4511 22.3457 24.7029 21.0414 25.5708 19.4894C26.4386 17.9374 26.8935 16.1894 26.8921 14.4122V14.4122Z"
      fill="#D2D9E3"
    />
    <path
      d="M29.1436 4H26.1169H26.0741H26.0227H22.7776C22.5505 4 22.3327 4.09001 22.1722 4.25023C22.0116 4.41044 21.9214 4.62774 21.9214 4.85432V5.53779C23.4405 6.46805 24.6953 7.77118 25.5662 9.32285C26.437 10.8745 26.8949 12.623 26.896 14.4014V9.82862C26.896 9.60204 26.9862 9.38474 27.1468 9.22452C27.3074 9.06431 27.5252 8.9743 27.7522 8.9743H29.1436C29.3707 8.9743 29.5885 8.88429 29.749 8.72407C29.9096 8.56386 29.9998 8.34656 29.9998 8.11997V4.86714C30.0015 4.75387 29.9806 4.64141 29.9384 4.53627C29.8961 4.43114 29.8333 4.33543 29.7536 4.25474C29.674 4.17404 29.579 4.10997 29.4743 4.06624C29.3695 4.0225 29.2571 3.99999 29.1436 4V4Z"
      fill="#D2D9E3"
    />
    <path
      d="M24.3408 31.1228C25.6802 31.1228 26.766 30.0394 26.766 28.703C26.766 27.3665 25.6802 26.2831 24.3408 26.2831C23.0013 26.2831 21.9155 27.3665 21.9155 28.703C21.9155 30.0394 23.0013 31.1228 24.3408 31.1228Z"
      fill="#8991AC"
    />
    <path
      d="M8.44673 31.1228C9.78616 31.1228 10.872 30.0394 10.872 28.7029C10.872 27.3664 9.78616 26.283 8.44673 26.283C7.1073 26.283 6.02148 27.3664 6.02148 28.7029C6.02148 30.0394 7.1073 31.1228 8.44673 31.1228Z"
      fill="#8991AC"
    />
    <path
      d="M16.4416 4.00064C15.0044 4.00241 13.5829 4.3001 12.2663 4.87508C10.9496 5.45006 9.76604 6.28995 8.78959 7.34223C7.81313 8.3945 7.06482 9.63651 6.59145 10.9906C6.11808 12.3446 5.92984 13.7816 6.03852 15.2115C6.03852 15.2457 6.03852 15.2777 6.03852 15.3098H6V23.947C6 24.1736 6.09019 24.3909 6.25077 24.5511C6.41134 24.7113 6.62914 24.8013 6.85622 24.8013H15.7566C17.9079 24.9408 20.0493 24.4078 21.8829 23.2763V14.4127C21.8833 15.4905 21.5634 16.5442 20.9635 17.4405C20.3637 18.3368 19.5109 19.0355 18.513 19.4482C17.5151 19.8608 16.417 19.9689 15.3576 19.7587C14.2981 19.5486 13.325 19.0296 12.5612 18.2675C11.7974 17.5054 11.2772 16.5344 11.0666 15.4773C10.856 14.4202 10.9643 13.3245 11.3779 12.3288C11.7914 11.3332 12.4916 10.4823 13.3899 9.88374C14.2883 9.28522 15.3443 8.96598 16.4245 8.9664C17.8716 8.9681 19.259 9.54245 20.2823 10.5635C21.3056 11.5845 21.8812 12.9688 21.8829 14.4127V5.5491C20.2486 4.53845 18.3644 4.00224 16.4416 4.00064Z"
      fill="#8991AC"
    />
  </svg>
);

export const SKProfileIcon = ({ className }) => (
  <svg
    className={className}
    width="88"
    height="88"
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="87"
      height="87"
      rx="43.5"
      fill="white"
      stroke="#DCE0EB"
    />
    <g clipPath="url(#clip0_29196_28066)">
      <path
        d="M43.5825 51.4209C44.1194 52.2498 44.6149 53.0374 45.1105 53.8249C46.5558 56.0632 47.9599 58.3014 49.3639 60.5811C49.5291 60.8713 49.7356 60.9956 50.1072 60.9956C53.6174 60.9956 57.1275 60.9956 60.6789 60.9956C60.8441 60.9956 61.0093 60.9541 61.2158 60.9541C60.555 59.9594 59.9769 59.006 59.3988 58.0942C56.5907 53.7835 53.7826 49.5142 51.0157 45.2035C50.8506 44.9548 50.6854 44.7061 50.5202 44.4574C50.1898 44.0429 50.2724 43.7113 50.6028 43.2969C51.7591 42.0119 52.8741 40.6441 54.0303 39.3592C54.2368 39.1105 54.6911 38.9447 55.0214 38.9447C60.6789 38.8618 65.7583 42.6337 67.3275 48.0635C68.8968 53.4104 67.6579 58.1771 64.2304 62.4049C61.9178 65.2649 58.9032 67.0472 55.2692 67.7518C54.5259 67.9176 53.7413 68.0005 52.9566 68.0005C46.6797 68.0005 40.3615 68.0005 34.0845 68.0005C33.7542 68.0005 33.4651 67.959 33.1347 67.8761C31.6481 67.5031 31.0699 65.8451 32.061 64.6846C34.6627 61.6173 37.2643 58.5916 39.8659 55.5658C40.8983 54.3637 41.9307 53.2032 42.9631 52.0012C43.1283 51.8768 43.3348 51.6696 43.5825 51.4209Z"
        fill="#EDEFF7"
      />
      <path
        d="M33.4242 49.5966C31.5246 49.3479 29.7902 48.8505 28.1384 48.0215C24.0501 45.9076 21.4485 42.5502 20.3748 38.0323C18.9708 32.3952 21.5311 24.9758 27.7254 21.8257C30.2032 20.5822 32.7222 19.9605 35.489 20.0019C41.3943 20.0434 47.2996 20.0019 53.2049 20.0019C54.6089 20.0019 55.5174 20.8724 55.2696 22.1158C55.1871 22.5303 54.9806 22.9448 54.7328 23.2764C51.7595 27.297 48.7449 31.3175 45.7303 35.3795C45.2348 36.0427 44.7393 36.6645 44.2437 37.3277C44.2024 37.4106 44.1198 37.452 43.9546 37.5763C43.9546 33.9288 43.9546 30.4057 43.9546 26.841C40.4445 26.841 36.9344 26.841 33.4242 26.841C33.4242 34.3848 33.4242 41.9699 33.4242 49.5966Z"
        fill="#E3E5ED"
      />
    </g>
    <defs>
      <clipPath id="clip0_29196_28066">
        <rect x="20" y="20" width="48" height="48" rx="11.25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const AccountUnselectedIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 29C11.1456 29 7 28.213 7 25.0613C7 21.9095 11.1193 19 16 19C20.8544 19 25 21.8813 25 25.0331C25 28.1835 20.8807 29 16 29Z"
      fill="#D2D9E3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0007 17C19.3143 17 22 14.3143 22 11.0006C22 7.68698 19.3143 5 16.0007 5C12.687 5 10 7.68698 10 11.0006C9.98888 14.3031 12.6559 16.9888 15.9584 17C15.9733 17 15.987 17 16.0007 17Z"
      fill="#8991AC"
    />
  </svg>
);

export const AccountSelectedIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 29C11.1456 29 7 28.213 7 25.0613C7 21.9095 11.1193 19 16 19C20.8544 19 25 21.8813 25 25.0331C25 28.1835 20.8807 29 16 29Z"
      fill="#00954B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0007 17C19.3143 17 22 14.3143 22 11.0006C22 7.68698 19.3143 5 16.0007 5C12.687 5 10 7.68698 10 11.0006C9.98888 14.3031 12.6559 16.9888 15.9584 17C15.9733 17 15.987 17 16.0007 17Z"
      fill="#1A51A3"
    />
  </svg>
);

export const CartIcon = ({ className, quantity }) => (
  <div className="relative col j-center">
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5518 28C13.6418 28 14.5255 27.1163 14.5255 26.0263C14.5255 24.9363 13.6418 24.0526 12.5518 24.0526C11.4618 24.0526 10.5781 24.9363 10.5781 26.0263C10.5781 27.1163 11.4618 28 12.5518 28Z"
        fill="#1A51A3"
      />
      <path
        d="M23.0791 28C24.1692 28 25.0528 27.1163 25.0528 26.0263C25.0528 24.9363 24.1692 24.0526 23.0791 24.0526C21.9891 24.0526 21.1055 24.9363 21.1055 26.0263C21.1055 27.1163 21.9891 28 23.0791 28Z"
        fill="#1A51A3"
      />
      <path
        d="M26.3682 22.7366H10.5789C9.56857 22.7366 8.59673 22.3492 7.86346 21.6542C7.1302 20.9592 6.69134 20.0095 6.63725 19.0006C6.58315 17.9917 6.91794 17.0005 7.57268 16.2311C8.22742 15.4616 9.15227 14.9725 10.1568 14.8645L7.79313 6.58562C7.71367 6.31127 7.54747 6.07003 7.31942 5.89804C7.09138 5.72605 6.81375 5.63255 6.52812 5.63155H5.31578C4.96681 5.63155 4.63214 5.49293 4.38538 5.24617C4.13863 4.99941 4 4.66474 4 4.31578C4 3.96681 4.13863 3.63214 4.38538 3.38538C4.63214 3.13863 4.96681 3 5.31578 3H6.52812C7.38527 3.00273 8.21843 3.28327 8.90266 3.79954C9.58689 4.31581 10.0853 5.04 10.3232 5.86349L13.1597 15.7967C13.2156 15.9925 13.2253 16.1986 13.188 16.3987C13.1507 16.5989 13.0675 16.7877 12.9449 16.9502C12.8222 17.1128 12.6636 17.2446 12.4813 17.3354C12.2991 17.4263 12.0983 17.4735 11.8947 17.4735H10.5789C10.2299 17.4735 9.89524 17.6122 9.64849 17.8589C9.40173 18.1057 9.26311 18.4404 9.26311 18.7893C9.26311 19.1383 9.40173 19.473 9.64849 19.7197C9.89524 19.9665 10.2299 20.1051 10.5789 20.1051H26.3682C26.7172 20.1051 27.0518 20.2437 27.2986 20.4905C27.5453 20.7372 27.684 21.0719 27.684 21.4209C27.684 21.7698 27.5453 22.1045 27.2986 22.3513C27.0518 22.598 26.7172 22.7366 26.3682 22.7366Z"
        fill="#00954B"
      />
      <path
        d="M10.3239 5.86316L13.1605 15.7964C13.2163 15.9922 13.226 16.1982 13.1888 16.3984C13.1515 16.5986 13.0683 16.7874 12.9456 16.9499C12.823 17.1124 12.6643 17.2443 12.4821 17.3351C12.2999 17.4259 12.099 17.4732 11.8954 17.4732H21.7522C22.6093 17.4705 23.4425 17.19 24.1268 16.6739C24.8111 16.1577 25.3097 15.4337 25.5479 14.6104L27.634 7.30871C27.69 7.11288 27.6997 6.90674 27.6625 6.70651C27.6252 6.50628 27.542 6.31742 27.4194 6.15481C27.2968 5.9922 27.1381 5.86028 26.9558 5.76942C26.7735 5.67857 26.5726 5.63126 26.369 5.63123H10.2363C10.2641 5.70937 10.301 5.78261 10.3239 5.86316Z"
        fill="#1A51A3"
      />
    </svg>
    {!!quantity && (
      <svg
        className="superscript"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="9" fill="#F44336" stroke="white" />
        {quantity < 100 ? (
          <text
            x={quantity < 10 ? '7.8' : '5.5'}
            y="13.6"
            fontSize="9"
            fontWeight="500"
            fill="white"
          >
            {quantity}
          </text>
        ) : (
          <>
            <text x="3" y="13.6" fontSize="9" fontWeight="500" fill="white">
              99
            </text>
            <text fill="white" x="13" y="12" fontSize="7" fontWeight="600">
              +
            </text>
          </>
        )}
      </svg>
    )}
  </div>
);

export const CartUnselectedIcon = ({ className, quantity }) => (
  <div className="relative col j-center">
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5518 28.0001C13.6418 28.0001 14.5255 27.1164 14.5255 26.0264C14.5255 24.9364 13.6418 24.0527 12.5518 24.0527C11.4618 24.0527 10.5781 24.9364 10.5781 26.0264C10.5781 27.1164 11.4618 28.0001 12.5518 28.0001Z"
        fill="#8991AC"
      />
      <path
        d="M23.0791 28.0001C24.1692 28.0001 25.0528 27.1164 25.0528 26.0264C25.0528 24.9364 24.1692 24.0527 23.0791 24.0527C21.9891 24.0527 21.1055 24.9364 21.1055 26.0264C21.1055 27.1164 21.9891 28.0001 23.0791 28.0001Z"
        fill="#8991AC"
      />
      <path
        d="M26.3682 22.7366H10.5789C9.56857 22.7366 8.59673 22.3492 7.86346 21.6542C7.1302 20.9592 6.69134 20.0095 6.63725 19.0006C6.58315 17.9917 6.91794 17.0005 7.57268 16.2311C8.22742 15.4616 9.15227 14.9725 10.1568 14.8645L7.79313 6.58562C7.71367 6.31127 7.54747 6.07003 7.31942 5.89804C7.09138 5.72605 6.81375 5.63255 6.52812 5.63155H5.31578C4.96681 5.63155 4.63214 5.49293 4.38538 5.24617C4.13863 4.99941 4 4.66474 4 4.31578C4 3.96681 4.13863 3.63214 4.38538 3.38538C4.63214 3.13863 4.96681 3 5.31578 3H6.52812C7.38527 3.00273 8.21843 3.28327 8.90266 3.79954C9.58689 4.31581 10.0853 5.04 10.3232 5.86349L13.1597 15.7967C13.2156 15.9925 13.2253 16.1986 13.188 16.3987C13.1507 16.5989 13.0675 16.7877 12.9449 16.9502C12.8222 17.1128 12.6636 17.2446 12.4813 17.3354C12.2991 17.4263 12.0983 17.4735 11.8947 17.4735H10.5789C10.2299 17.4735 9.89524 17.6122 9.64849 17.8589C9.40173 18.1057 9.26311 18.4404 9.26311 18.7893C9.26311 19.1383 9.40173 19.473 9.64849 19.7197C9.89524 19.9665 10.2299 20.1051 10.5789 20.1051H26.3682C26.7172 20.1051 27.0518 20.2437 27.2986 20.4905C27.5453 20.7372 27.684 21.0719 27.684 21.4209C27.684 21.7698 27.5453 22.1045 27.2986 22.3513C27.0518 22.598 26.7172 22.7366 26.3682 22.7366Z"
        fill="#D2D9E3"
      />
      <path
        d="M10.3235 5.86328L13.16 15.7965C13.2159 15.9923 13.2256 16.1984 13.1883 16.3985C13.151 16.5987 13.0678 16.7875 12.9451 16.95C12.8225 17.1126 12.6639 17.2444 12.4816 17.3352C12.2994 17.426 12.0986 17.4733 11.8949 17.4733H21.7517C22.6088 17.4706 23.442 17.1901 24.1263 16.674C24.8106 16.1579 25.3092 15.4339 25.5474 14.6105L27.6335 7.30883C27.6895 7.11301 27.6992 6.90686 27.662 6.70663C27.6247 6.5064 27.5415 6.31755 27.4189 6.15494C27.2963 5.99233 27.1376 5.8604 26.9553 5.76954C26.773 5.67869 26.5721 5.63138 26.3685 5.63135H10.2358C10.2636 5.70949 10.3005 5.78273 10.3235 5.86328Z"
        fill="#8991AC"
      />
    </svg>

    {!!quantity && (
      <svg
        className="superscript"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="9" fill="#F44336" stroke="white" />

        {quantity < 100 ? (
          <text
            x={quantity < 10 ? '7.8' : '5.5'}
            y="13.6"
            fontSize="9"
            fontWeight="500"
            fill="white"
          >
            {quantity}
          </text>
        ) : (
          <>
            <text x="3" y="13.6" fontSize="9" fontWeight="500" fill="white">
              99
            </text>
            <text fill="white" x="13" y="12" fontSize="7" fontWeight="600">
              +
            </text>
          </>
        )}
      </svg>
    )}
  </div>
);

export const SearchIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.722 3C16.5657 3 19.2929 4.1418 21.3037 6.1742C23.3145 8.20661 24.4441 10.9631 24.4441 13.8374C24.4441 19.8227 19.6437 24.6748 13.722 24.6748C7.80043 24.6748 3 19.8227 3 13.8374C3 7.85207 7.80043 3 13.722 3Z"
      fill="#1A51A3"
    />
    <path
      d="M24.2669 22.5679C23.7838 22.088 23.0093 22.088 22.5261 22.5679C22.2756 22.8202 22.1348 23.163 22.1348 23.5205C22.1348 23.8269 22.2382 24.1225 22.4255 24.3596L22.5261 24.4731L25.1761 27.5103C25.8222 28.1633 26.8696 28.1633 27.5157 27.5103C28.1617 26.8573 28.1617 25.7985 27.5157 25.1455H27.4602L24.2669 22.5679Z"
      fill="#00954B"
    />
  </svg>
);

export const SearchUnselectedIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.722 3C16.5657 3 19.2929 4.1418 21.3037 6.1742C23.3145 8.20661 24.4441 10.9631 24.4441 13.8374C24.4441 19.8227 19.6437 24.6748 13.722 24.6748C7.80043 24.6748 3 19.8227 3 13.8374C3 7.85207 7.80043 3 13.722 3Z"
      fill="#8991AC"
    />
    <path
      d="M24.2669 22.5679C23.7838 22.088 23.0093 22.088 22.5261 22.5679C22.2756 22.8202 22.1348 23.163 22.1348 23.5205C22.1348 23.8269 22.2382 24.1225 22.4255 24.3596L22.5261 24.4731L25.1761 27.5103C25.8222 28.1633 26.8696 28.1633 27.5157 27.5103C28.1617 26.8573 28.1617 25.7985 27.5157 25.1455H27.4602L24.2669 22.5679Z"
      fill="#D2D9E3"
    />
  </svg>
);
