/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_ALL_COUNTRIES = gql`
  query GET_ALL_COUNTRIES {
    getAllCountries {
      countryName
      countryCode
      countryPhoneCode
      baseUrl
      currencySymbol
      currency
      flagUrl
      phoneValidation
      samplePhone
      orderConfig
      storeTypes
      bnplTermsURL
    }
  }
`;
