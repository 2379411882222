// import { getCookie } from '@/utils/helper';
// import { AUTH_TOKEN } from '@/utils/oauth';
import { logout } from '@/services/auth';
import { getCookie } from '@/utils/helper';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const isLocal = process.env.NEXT_PUBLIC_ENV === 'local';

const authLink = new ApolloLink((operation, forward) => {
  // const loginToken = getCookie(AUTH_TOKEN);
  operation.setContext({
    headers: {
      // authorization:
      // `Bearer ${loginToken}` || 'DEV d1ed7d52-8631-445a-a633-8ef3dc90cf80',
      authorization: 'DEV 44030dd9-c53a-42fa-89d1-69dd3a9f2f68',
      platform: 'ALERZOSHOP_PWA',
    },
  });
  return forward(operation);
});

function createApolloClient(headers) {
  const linkOptions = {
    uri: process.env.NEXT_PUBLIC_GRAPHQL,
    credentials: 'include',
    fetchOptions: {
      mode: 'cors',
    },
  };
  if (headers) {
    linkOptions.headers = { ...headers, platform: 'ALERZOSHOP_PWA' };
  } else {
    linkOptions.headers = { platform: 'ALERZOSHOP_PWA' };
  }

  const link = new HttpLink(linkOptions);

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
        if (path?.includes('getCustomer')) {
          console.log('logout old user');
          logout().catch((err) => console.log('cannot logout from errorLink', err));
        }
      });
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  return new ApolloClient({
    credentials: 'include',
    link: isLocal ? authLink.concat(link) : from([errorLink, link]),
    cache: new InMemoryCache(),
  });
}

export function getAuthCookie(context) {
  const { query } = context;
  const { cookies } = context.req || {};
  const cookieUserId = getCookie('veedezId');
  const userId = query?.userId;
  if (userId && userId !== cookieUserId) {
    return false;
  }
  return isLocal && !cookies?.AUTH_ID ? true : cookies?.AUTH_ID;
  // return cookies?.AUTH_ID;
}

export function configureApolloClientSSR(context) {
  // context?.res?.setHeader('Cache-Control', 'no-cache');
  const authCookie = getAuthCookie(context);
  let headers = { platform: 'ALERZOSHOP_PWA' };
  if (authCookie) {
    const Cookie = `AUTH_ID=${authCookie}`;
    headers = { Cookie, platform: 'ALERZOSHOP_PWA' };
  }
  return createApolloClient(headers);
}

const client = createApolloClient();
export default client;
