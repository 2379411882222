export const AUTH_TOKEN = 'AUTH_TOKEN';
export const OauthBaseUri = `${process.env.OAUTH_BASE}/oauth2/auth?client_id=alerzoshop&scope=openid&response_type=code&redirect_uri=http%3A%2F%2F127.0.0.1%3A5555%2Fcallback&response_mode=query`;
export const GetTokenBaseUri = `${process.env.NEXT_PUBLIC_API_URL}/oauth/token`;
export const DEFAULT_LOCATION = {
  ng: {
    lat: 6.465422,
    long: 3.406448,
  },
  ug: {
    lat: 0.3476,
    long: 32.5825,
  },
};
export const RECENT_SEARCH_NAMES = {
  locations: 'recentLocations',
  searches: 'recentSearches',
  salesIq: 'siqlsdb',
  salesIqData: 'utsdb',
};
