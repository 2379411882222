// Core Packages
import React, { createContext, useEffect, useState } from 'react';

// utils
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  useQuery,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
// import { generateIdToken } from '../services/auth';
// import { GET_ALL_COUNTRIES } from '@/graphql/countries';
import { GET_APP_INFORMATION } from '@/graphql/misc';
import { checkSite } from '@/utils/helper';

export const GqlContext = createContext({});

export default function GqlProvider({
  children,
  allCountries,
  baseUrl = process.env.NEXT_PUBLIC_GRAPHQL,
  activeCountry,
  veedez,
}) {
  const { data: appInfoFound } = useQuery(GET_APP_INFORMATION);
  const [appInfo, setAppInfo] = useState({});
  const [isVeedez, setIsVeedez] = useState(false);

  const [token, setToken] = useState('');
  const [refreshApp, setAppRefresh] = useState(false);

  const getClient = () => {
    const httpLink = new HttpLink({
      uri: baseUrl,
    });

    const authMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      });
      return forward(operation);
    });

    const platformMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          platform: 'ALERZOSHOP_PWA',
        },
      }));

      return forward(operation);
    });

    const link = new RetryLink({
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
      },
      attempts: {
        max: 5,
        retryIf: (error) => {
          if (error.statusCode === 401) {
            // generateIdToken();
            return true;
          }
          return false;
        },
      },
    });

    return new ApolloClient({
      link: ApolloLink.from([
        link,
        authMiddleware,
        platformMiddleware,
        httpLink,
      ]),
      cache: new InMemoryCache(),
    });
  };

  const [client, setClient] = useState(getClient());

  useEffect(() => {
    const clientFound = getClient();

    setClient(clientFound);
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (appInfoFound?.getAppInformation) {
      setAppInfo(appInfoFound?.getAppInformation);
    }
  }, [appInfoFound]);

  useEffect(() => {
    if (veedez) setIsVeedez(veedez);
    else if (typeof window !== 'undefined') {
      const veedezInfo = sessionStorage?.getItem('veedez') === 'true';
      setIsVeedez(veedezInfo);
    }
  }, [veedez]);

  return (
    <GqlContext.Provider
      value={{
        token,
        client,
        setToken,
        refreshApp,
        setAppRefresh,
        countries: allCountries,
        country: activeCountry,
        site: checkSite(isVeedez),
        appInfo,
      }}
    >
      {children}
    </GqlContext.Provider>
  );
}
