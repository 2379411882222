import english from './languages/english.json';
import hausa from './languages/hausa.json';
import igbo from './languages/igbo.json';
import yoruba from './languages/yoruba.json';

const Languages = {
  english,
  yoruba,
  hausa,
  igbo,
};

export const LanguageNames = Object.fromEntries(
  Object.keys(Languages).map((it) => [it, it]),
);

export const NativeLanguageNames = {
  english: 'English',
  yoruba: 'ˈyôrəbə - YO',
  hausa: 'ˈhousə - HA',
  igbo: 'ˈēˌbō - IBO',
};

export const LanguageShortForms = {
  english: 'EN',
  yoruba: 'YO',
  hausa: 'HA',
  igbo: 'IBO',
};

export default Languages;
