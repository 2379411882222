/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';

// Core Packages
import Link from 'next/link';
import classnames from 'classnames';

// Utilities
import { useTranslation } from 'react-i18next';
import { GqlContext } from '@/contexts/gql';

// Custom Components
import {
  SelectedAlerzoIcon,
  UnselectedAlerzoIcon,
  CategoriesIcon,
  CategoriesUnselectedIcon,
  SearchIcon,
  SearchUnselectedIcon,
  CartIcon,
  CartUnselectedIcon,
  AccountSelectedIcon,
  AccountUnselectedIcon,
} from '@/components/BottomNavigationBar/icons';
import { SKColouredIcon, SKGrayedOutIcon } from '@/assets/icons/desktop';

// Styles
import styles from './style.module.css';

export default function BottomNavigationBar({
  currentPath = '',
  getCartItems,
}) {
  const { t: translate } = useTranslation();
  const cartItems = getCartItems();
  const { site } = useContext(GqlContext);
  const AppIconSelected = site?.isSK ? (
    <SKColouredIcon size={20} />
  ) : site?.isAlerzo ? (
    <SelectedAlerzoIcon />
  ) : (
    <SelectedAlerzoIcon />
  );

  const AppIconGrayedOut = site?.isSK ? (
    <SKGrayedOutIcon size={20} />
  ) : site?.isAlerzo ? (
    <UnselectedAlerzoIcon />
  ) : (
    <UnselectedAlerzoIcon />
  );

  const isActive = (path) => (path === '/' ? currentPath === path : currentPath.startsWith(path));

  const getTabClasses = (path, ...classes) => classnames('col j-center a-center flex-1', ...classes, {
    [styles.active]: isActive(path),
  });

  return (
    <footer className={styles.footer}>
      <Link href="/">
        <div className={getTabClasses('/')}>
          {isActive('/')
            ? React.cloneElement(AppIconSelected)
            : React.cloneElement(AppIconGrayedOut)}
          <span className={styles.label}>
            {site?.isSK ? 'SK Express' : 'Shop'}
          </span>
        </div>
      </Link>
      <Link href="/category">
        <div className={getTabClasses('/category')}>
          {currentPath.startsWith('/category') ? (
            <CategoriesIcon className={styles.icon} />
          ) : (
            <CategoriesUnselectedIcon className={styles.icon} />
          )}
          <span className={styles.label}>{translate('Categories')}</span>
        </div>
      </Link>
      <Link href="/search">
        <div className={getTabClasses('/search')}>
          {currentPath.startsWith('/search') ? (
            <SearchIcon className={styles.icon} />
          ) : (
            <SearchUnselectedIcon className={styles.icon} />
          )}
          <span className={styles.label}>{translate('Search')}</span>
        </div>
      </Link>
      <Link href="/cart">
        <div className={getTabClasses('/cart')}>
          {currentPath === '/cart' ? (
            <CartIcon className={styles.icon} quantity={cartItems.length} />
          ) : (
            <CartUnselectedIcon
              className={styles.icon}
              quantity={cartItems.length}
            />
          )}
          <span className={styles.label}>{translate('Cart')}</span>
        </div>
      </Link>
      <Link href="/account">
        <div className={getTabClasses('/account')}>
          {currentPath === '/account' ? (
            <AccountSelectedIcon className={styles.icon} />
          ) : (
            <AccountUnselectedIcon className={styles.icon} />
          )}
          <span className={styles.label}>{translate('Account')}</span>
        </div>
      </Link>
    </footer>
  );
}
