import { gql } from '@apollo/client';

export const GET_RECOMMENDED_PRODUCTS_FOR_CUSTOMER = gql`
  query GET_RECOMMENDED_PRODUCTS_FOR_CUSTOMER(
    $customerId: String!
    $warehouseId: String
    $size: Int
    $verticals: [String]
  ) {
    getRecommendedProductsForCustomer(
      customerId: $customerId
      warehouseId: $warehouseId
      verticals: $verticals
      size: $size
      outOfStock: true
      page: 1
    ) {
      nodes {
        _id
        sellerId
        brand
        category
        subCategory
        brand
        product_SKU
        verticalId
        vertical
        variant
        image {
          original
          medium
          small
          large
        }
        images {
          imageUrl
        }
        pricing {
          wholesalePrice
          retailPrice
        }
        productMainId
        stockValue
        minWholeSaleQty
        bundle {
          _id
          bundleName
          sellerId
          minimumQuantity
          bundleType
          maxDiscountedQty
          discountType
          discount
          mandatory
          productType
          __typename @skip(if: true)
        }
        discountPricing {
          wholesalePrice
          retailPrice
        }
        cityWisePricing {
          minimumQuantity
          maximumQuantityPerOrder
          pricing {
            wholesalePrice
            retailPrice
          }
        }
        promotion {
          promotionName
          discount
          discountType
        }
        warehouse
        order_qty_max_limit
      }
    }
  }
`;

export const GET_TRENDING_PRODUCTS_BY_CITY = gql`
  query GET_TRENDING_PRODUCTS_BY_CITY(
    $cityId: String!
    $warehouseId: String!
    $size: Int
    $verticals: [String]
  ) {
    getTrendingProductsByCity(
      cityId: $cityId
      warehouseId: $warehouseId
      verticals: $verticals
      size: $size
      outOfStock: true
      page: 1
    ) {
      nodes {
        _id
        sellerId
        brand
        category
        subCategory
        brand
        product_SKU
        verticalId
        vertical
        variant
        image {
          original
          medium
          small
          large
        }
        images {
          imageUrl
        }
        pricing {
          wholesalePrice
          retailPrice
        }
        productMainId
        stockValue
        minWholeSaleQty
        bundle {
          _id
          bundleName
          sellerId
          minimumQuantity
          bundleType
          maxDiscountedQty
          discountType
          discount
          mandatory
          productType
          __typename @skip(if: true)
        }
        discountPricing {
          wholesalePrice
          retailPrice
        }
        cityWisePricing {
          minimumQuantity
          maximumQuantityPerOrder
          pricing {
            wholesalePrice
            retailPrice
          }
        }
        promotion {
          promotionName
          discount
          discountType
        }
        warehouse
        order_qty_max_limit
      }
    }
  }
`;

export const GET_BEST_SELLING_PRODUCTS_BY_CITY = gql`
  query GET_BEST_SELLING_PRODUCTS_BY_CITY(
    $cityId: String!
    $warehouseId: String!
    $size: Int
    $verticals: [String]
  ) {
    getBestSellingProductByCity(
      cityId: $cityId
      warehouseId: $warehouseId
      verticals: $verticals
      size: $size
      outOfStock: true
      page: 1
    ) {
      nodes {
        _id
        sellerId
        brand
        category
        subCategory
        brand
        product_SKU
        verticalId
        vertical
        variant
        image {
          original
          medium
          small
          large
        }
        images {
          imageUrl
        }
        pricing {
          wholesalePrice
          retailPrice
        }
        productMainId
        stockValue
        minWholeSaleQty
        bundle {
          _id
          sellerId
          bundleName
          minimumQuantity
          bundleType
          maxDiscountedQty
          discountType
          discount
          mandatory
          productType
          __typename @skip(if: true)
        }
        discountPricing {
          wholesalePrice
          retailPrice
        }
        cityWisePricing {
          minimumQuantity
          maximumQuantityPerOrder
          pricing {
            wholesalePrice
            retailPrice
          }
        }
        promotion {
          promotionName
          discount
          discountType
        }
        warehouse
        order_qty_max_limit
      }
    }
  }
`;

export const GET_STATES_CITIES = gql`
  query GET_STATES_CITIES {
    getStateAndCities {
      stateId
      stateName
      Cities {
        cityId
        cityLat
        cityLong
        cityName
        Landmarks {
          landmarkId
          warehouseId
          landmarkName
          lat
          long
        }
      }
    }
  }
`;

export const GET_BANNERS = gql`
  query FETCH_BANNERS($getInActive: Boolean, $warehouseId: String) {
    fetchBanners(getInActive: $getInActive, warehouseId: $warehouseId) {
      top {
        _id
        image
        isActive
        redirectTo
        category
        position
        params {
          name
          value
        }
        desktopVersion {
          language
          imageUrl
        }
        mobileVersion {
          language
          imageUrl
        }
        redirectType
        redirectValue
      }
      bottom {
        _id
        image
        isActive
        redirectTo
        category
        position
        params {
          name
          value
        }
        desktopVersion {
          language
          imageUrl
        }
        mobileVersion {
          language
          imageUrl
        }
        redirectType
        redirectValue
      }
    }
  }
`;

export const FETCH_ALERZO_CUSTOMER_POPULAR_SEARCHES = gql`
  query FETCH_ALERZO_CUSTOMER_POPULAR_SEARCHES {
    fetchAlerzoCustomerPopularSearches {
      search_term: search_term
      search_text: search_text
      times_searched
    }
  }
`;

export const FETCH_ALERZO_CUSTOMER_RECENT_SEARCHES = gql`
  query FETCH_ALERZO_CUSTOMER_RECENT_SEARCHES($customerId: String) {
    fetchAlerzoCustomerRecentSearches(customerId: $customerId) {
      searchTerm
    }
  }
`;

export const ADD_CUSTOMER_RECENT_SEARCHES = gql`
  mutation ADD_CUSTOMER_RECENT_SEARCHES(
    $customerId: String
    $lat: Float
    $long: Float
    $searchTerm: String
  ) {
    addCustomerRecentSearches(
      customerId: $customerId
      lat: $lat
      long: $long
      searchTerm: $searchTerm
    )
  }
`;
