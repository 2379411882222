import { createContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_VERTICALS } from '@/graphql/products';

export const SectorContext = createContext({});

export default function SectorProvider({ children }) {
  const { data: verticalInfo } = useQuery(GET_VERTICALS, {
    fetchPolicy: 'network-only',
    variables: {
      size: 9999,
      populateSubcategories: false,
    },
  });
  const [sectors, setSectors] = useState([]);

  useEffect(() => {
    if (verticalInfo?.fetchVerticals?.nodes) {
      setSectors(verticalInfo?.fetchVerticals?.nodes);
    }
  }, [verticalInfo]);

  return (
    <SectorContext.Provider value={{ sectors }}>
      {children}
    </SectorContext.Provider>
  );
}
