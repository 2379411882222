import { gql } from '@apollo/client';

export const GET_BUNDLES = gql`
  query GET_BUNDLES {
    fetchBundles(page: 1, size: 200) {
      nodes {
        _id
        bundleName
        bundleCities {
          bundleCityId
          minimumQuantity
        }
        bundleProducts {
          _id
          variant
          isActive
          product_SKU
          image {
            original
            medium
            small
            large
          }
          images {
            thumbnailUrl
            imageUrl
          }
          pricing {
            wholesalePrice
            retailPrice
          }
        }
      }
    }
  }
`;

export const GET_BUNDLE = gql`
  query GET_BUNDLE($bundleId: String!) {
    fetchBundleDetail(bundleId: $bundleId) {
      _id
      bundleName
      sellerId
      bundleType
      bundleCities {
        bundleCityId
        minimumQuantity
      }
    }
  }
`;

export const GET_BUNDLE_PRODUCTS = gql`
  query GET_BUNDLE_PRODUCTS(
    $bundleId: String!
    $city: String
    $warehouse: String
  ) {
    getBundleProducts(bundleId: $bundleId, city: $city, warehouse: $warehouse) {
      images
      description
      products {
        nodes {
          _id
          brand
          category
          subCategory
          brand
          product_SKU
          variant
          image {
            original
            medium
            small
            large
          }
          images {
            imageUrl
          }
          vertical
          verticalId
          pricing {
            wholesalePrice
            retailPrice
          }
          stockValue
          productMainId
          minWholeSaleQty
          bundle {
            _id
            bundleName
            minimumQuantity
            sellerId
            bundleType
            maxDiscountedQty
            discountType
            discount
            mandatory
            productType
            __typename @skip(if: true)
          }
          discountPricing {
            wholesalePrice
            retailPrice
          }
          cityWisePricing {
            minimumQuantity
            maximumQuantityPerOrder
            pricing {
              wholesalePrice
              retailPrice
            }
          }
          promotion {
            promotionName
            discount
            discountType
          }
          hideFromAlerzo
          sellerId
          warehouse
          order_qty_max_limit
        }
      }
    }
  }
`;
