// Core Packages
import classnames from 'classnames';

// Material
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

// Styles
import styles from './style.module.css';

export const dialogSizes = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

export default function StyledDialog({
  className,
  fullscreen = false,
  children = null,
  disableBackdropClick = false,
  hideCloseButton = false,
  containerClassName,
  onClose,
  dialogSize,
  paperClasses = {},
}) {
  const dialogClasses = classnames(className, { fullscreen });
  const containerClasses = classnames(
    'col j-start a-stretch',
    containerClassName,
  );

  return (
    <Dialog
      className={dialogClasses}
      open
      keepMounted
      onClose={onClose}
      disableBackdropClick={disableBackdropClick}
      maxWidth={dialogSize}
      PaperProps={{ className: paperClasses }}
    >
      <DialogContent className={containerClasses}>
        {!hideCloseButton && (
          <IconButton
            data-test="component-styled-dialog-close"
            className={styles.closeBtn}
            aria-label="Close"
            onClick={onClose}
          >
            <ClearIcon />
          </IconButton>
        )}
        {children}
      </DialogContent>
    </Dialog>
  );
}
