// Core
import {
  useEffect, useMemo, useState, useContext,
} from 'react';
import Link from 'next/link';
import classnames from 'classnames';

// Utils
import { useTranslation } from 'react-i18next';
import { formatAmount } from '@/utils/helper';
import { GqlContext } from '@/contexts/gql';

// Styles
import styles from './style.module.css';

const ChevronRightIcon = ({ className }) => (
  <svg
    className={className}
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.29006 0.710022C0.900059 1.10002 0.900059 1.73002 1.29006 2.12002L5.17006 6.00002L1.29006 9.88002C0.900059 10.27 0.900059 10.9 1.29006 11.29C1.68006 11.68 2.31006 11.68 2.70006 11.29L7.29006 6.70002C7.68006 6.31002 7.68006 5.68002 7.29006 5.29002L2.70006 0.700022C2.32006 0.320022 1.68006 0.320022 1.29006 0.710022Z"
      fill="white"
    />
  </svg>
);

export default function ViewCart({
  cartItems,
  getCartItems,
  getTotalPriceOfCart,
  hasBottomNav,
  currentPath,
}) {
  const { t: translate } = useTranslation();
  const { country } = useContext(GqlContext);
  const omittedRoutes = ['/cart', '/account/edit', '/cart/checkout'];
  const [cartProducts, setCartProducts] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const cartTotal = useMemo(() => getTotalPriceOfCart(), [cartItems]);
  const [oldTotal, setOldTotal] = useState(0);
  const containerClasses = classnames(
    styles.container,
    'row j-space-between a-center',
    {
      [styles.padBottom]: hasBottomNav,
    },
  );

  const clearTimer = (timeoutId) => {
    clearTimeout(timeoutId);
    setTimeoutId(null);
  };

  const startTimer = () => {
    if (timeoutId) {
      clearTimer(timeoutId);
    }
    const id = setTimeout(() => clearTimer(id), 5000);
    setTimeoutId(id);
  };

  useEffect(() => {
    if (omittedRoutes.includes(currentPath)) {
      clearTimer(timeoutId);
    }

    return () => clearTimer(timeoutId);
  }, [currentPath]);

  useEffect(() => {
    if (!omittedRoutes.includes(currentPath)) {
      const products = getCartItems();
      if (products.length !== cartProducts.length) {
        setCartProducts(products);
      }

      const totalIncreased = oldTotal < cartTotal;
      setOldTotal(cartTotal);

      if (products.length && totalIncreased) {
        startTimer();
      } else {
        clearTimer(timeoutId);
      }
    }
  }, [cartItems, cartTotal]);

  return (
    timeoutId && (
      <div className={containerClasses}>
        <div className="col">
          <span className={styles.cartItems}>
            {`${cartProducts.length} ${translate('Items')}`}
          </span>
          <span className={styles.cartTotal}>
            {`${country?.currencySymbol} ${formatAmount(cartTotal, true)}`}
          </span>
        </div>
        <Link href="/cart">
          <div className="row a-center">
            <span className={styles.btnLabel}>{translate('View Cart')}</span>
            <ChevronRightIcon className={styles.icon} />
          </div>
        </Link>
      </div>
    )
  );
}
