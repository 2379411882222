import { gql } from '@apollo/client';

export const GENERATE_OTP = gql`
  query GENERATE_OTP($phone: String!) {
    generateLoginOTP(phoneNo: $phone) {
      status
      msg
    }
  }
`;

export const GET_AUTH_USER = gql`
  query GET_AUTH_USER {
    getCustomer {
      customerId
      phoneNo
      additionalPhones
      businessName
      isNumberVerified
      isBlackListed
      agentName
      customerIdentifier
      legacyUniqueId
      onboardDate
      verticals
      createdAt
      updatedAt
      fcmToken
      CustomerContacts {
        firstName
        lastName
        phoneNo
        isPrimary
        isVerified
        hasSmartPhone
        smartPhoneType
        email
      }
      Stores {
        storeId
        typeOfStore
        contactName
        address
        city
        cityId
        state
        stateId
        landmark
        landmarkId
        lat
        long
      }
      status
      firstOrderDate
      referredCode
    }
  }
`;

export const VERIFY_OTP = gql`
  query VALIDATE_OTP($phone: String!, $otp: String!) {
    validateCustomerLogin(phoneNo: $phone, otp: $otp) {
      jwt
      phoneNo
      status
      customer {
        customerId
        Stores {
          city
          state
          landmark
          lat
          long
        }
        status
      }
    }
  }
`;

export const SET_COOKIE = gql`
  query SET_COOKIE($token: String!) {
    exchangeToken(idToken: $token) {
      status
      cookie
    }
  }
`;

export const UPDATE_FCM_TOKEN = gql`
  mutation UPDATE_FCM_TOKEN(
    $customerId: String!
    $fcmToken: String!
    $city: String!
    $cityId: String!
  ) {
    updateCustomer(
      customerId: $customerId
      fcmToken: $fcmToken
      city: $city
      cityId: $cityId
    ) {
      customerId
      fcmToken
    }
  }
`;

export const UPDATE_AUTH_USER = gql`
  mutation UPDATE_AUTH_USER(
    $customerId: String!
    $phoneNo: String
    $businessName: String
    $typeOfStore: String
    $contactName: String
    $landmark: String
    $landmarkId: String
    $city: String!
    $cityId: String!
  ) {
    updateCustomer(
      city: $city
      cityId: $cityId
      customerId: $customerId
      phoneNo: $phoneNo
      businessName: $businessName
      typeOfStore: $typeOfStore
      contactName: $contactName
      landmark: $landmark
      landmarkId: $landmarkId
    ) {
      customerId
    }
  }
`;

export const CREATE_AUTH_USER = gql`
  mutation CREATE_AUTH_USER(
    $phone: String!
    $state: String!
    $city: String!
    $lat: Float
    $long: Float
    $typeOfStore: String!
    $contactName: String!
    $businessName: String!
    $address: String!
    $landmark: String
    $platform: String!
  ) {
    addCustomer(
      phoneNo: $phone
      state: $state
      city: $city
      lat: $lat
      long: $long
      typeOfStore: $typeOfStore
      contactName: $contactName
      businessName: $businessName
      address: $address
      landmark: $landmark
      platform: $platform
    ) {
      customerIdentifier
    }
  }
`;

export const UPDATE_NEW_USER = gql`
  mutation UPDATE_NEW_USER(
    $customerId: String!
    $status: StatusEnum
    $state: String
    $stateId: String
    $landmark: String
    $landmarkId: String
    $lat: Float
    $long: Float
    $city: String
    $cityId: String
    $referredCode: String
    $address: String
    $businessName: String
    $verticals: [String]
  ) {
    updateCustomer(
      customerId: $customerId
      status: $status
      state: $state
      stateId: $stateId
      city: $city
      cityId: $cityId
      lat: $lat
      long: $long
      landmark: $landmark
      landmarkId: $landmarkId
      referredCode: $referredCode
      address: $address
      businessName: $businessName
      verticals: $verticals
    ) {
      customerId
    }
  }
`;
