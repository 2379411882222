/* eslint-disable prefer-destructuring */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/messaging';

import firebaseConfig from './firebase-config.json';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);

  // Client-side-only code
  if (typeof window !== 'undefined') {
    // Disable messaging on unsupported environments
    if (firebase.messaging.isSupported()) {
      firebase.messaging();
    }
  }
}

export default firebase;
