/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
// Core Packages
/* eslint-disable max-len */
import {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useApolloClient } from '@apollo/client';
import { AuthUserContext } from '@/contexts/authUser';

// Utils
import { GET_BUNDLE, GET_BUNDLE_PRODUCTS } from '@/graphql/bundle';
import { calcPromotionPrice, getCookie } from '@/utils/helper';
import { CartContext } from './cart';

export const BundleContext = createContext({});

export default function BundleProvider({ children }) {
  const { authUser } = useContext(AuthUserContext);
  const {
    cartItems,
    getItemQuantityForBundle,
    getItemQuantityBySeller,
    getProductPrice,
    getCartItems,
    getProductMaxQty,
    updateProductQuantity,
  } = useContext(CartContext);
  const [bundles, setBundles] = useState({});
  const client = useApolloClient();
  const [bundleLoading, setBundleLoading] = useState({});

  const fetchBundleDetails = async (bundleId) => {
    if (!bundles[bundleId] && !bundleLoading[bundleId]) {
      setBundleLoading((prev) => ({
        ...prev,
        [bundleId]: true,
      }));

      const warehouseId = getCookie('warehouse');

      try {
        const bundleDetails = await client.query({
          query: GET_BUNDLE,
          variables: {
            bundleId,
          },
        });

        const bundleProducts = await client.query({
          query: GET_BUNDLE_PRODUCTS,
          variables: {
            bundleId,
            city: authUser?.Stores?.[0]?.cityId || '',
            warehouse: warehouseId,
          },
        });

        if (
          bundleDetails?.data?.fetchBundleDetail
          && bundleProducts?.data?.getBundleProducts?.products?.nodes
        ) {
          setBundles((prev) => ({
            ...prev,
            [bundleId]: {
              ...bundleDetails.data.fetchBundleDetail,
              bundleProducts:
                bundleProducts.data.getBundleProducts.products.nodes,
              bundleInfo: {
                images: bundleProducts.data.getBundleProducts.images,
                description: bundleProducts.data.getBundleProducts.description,
              },
            },
          }));
        }
      } catch (error) {
        console.error('Error while fetching bundle', bundleId, error);
      }

      setBundleLoading((prev) => ({
        ...prev,
        [bundleId]: false,
      }));
    }
  };

  const bundlesInCart = useMemo(() => {
    const cartProducts = Object.values(cartItems);
    const bundlesProducts = cartProducts
      .filter((c) => c?.bundle?.bundleType === 'DISCOUNT')
      .map((c) => c?.bundle?._id);
    return [...new Set(bundlesProducts)];
  }, [cartItems]);

  const promotionalProducts = useMemo(() => {
    const promotedProducts = {};
    bundlesInCart.forEach((b) => {
      if (!bundles[b] || !bundles[b].bundleProducts) {
        fetchBundleDetails(b);
      } else if (
        bundles[b].bundleType === 'DISCOUNT'
        && Array.isArray(bundles[b].bundleProducts)
      ) {
        const { activateWholesale } = getItemQuantityForBundle(
          b,
          bundles[b].sellerId,
        );

        if (activateWholesale) {
          const products = bundles[b].bundleProducts.filter(
            (p) => p?.bundle?.productType === 'PROMOTION',
          );
          products.forEach((p) => {
            // const seller = p.sellers?.find(
            //   (s) => s.sellerId === p.bundle.sellerId,
            // );

            if (
              !p?.bundle?.mandatory
              && !cartItems[`${p._id}_${p.bundle.sellerId}`]
              && p?.stockValue
            ) {
              promotedProducts[`${p._id}_${p.bundle.sellerId}`] = {
                ...p,
                // ...seller,
              };
            }
          });
        }
      }
    });

    return promotedProducts;
  }, [bundlesInCart, bundles]);

  const mandatoryPromotionalProducts = useMemo(() => {
    const promotedProducts = {};
    bundlesInCart.forEach((b) => {
      if (!bundles[b] || !bundles[b].bundleProducts) {
        fetchBundleDetails(b);
      } else if (
        bundles[b].bundleType === 'DISCOUNT'
        && Array.isArray(bundles[b].bundleProducts)
      ) {
        const { activateWholesale } = getItemQuantityForBundle(
          b,
          bundles[b].sellerId,
        );

        if (activateWholesale) {
          const products = bundles[b].bundleProducts.filter(
            (p) => p?.bundle?.productType === 'PROMOTION',
          );
          products.forEach((p) => {
            // const seller = p.sellers?.find(
            //   (s) => s.sellerId === p.bundle.sellerId,
            // );

            if (p?.bundle?.mandatory && p?.stockValue) {
              promotedProducts[`${p._id}_${p.bundle.sellerId}`] = {
                ...p,
                // ...seller,
              };
            }
          });
        }
      }
    });

    return promotedProducts;
  }, [bundlesInCart, bundles]);

  const getGrossTotalOfBundle = useCallback(
    (bundleId) => {
      let grossTotal = 0;
      const allCartItems = getCartItems();
      allCartItems.forEach((item) => {
        if (
          item.bundle?._id
          && `${item.bundle._id}` === `${bundleId}`
          && item.stockValue > 0
        ) {
          const productPrice = getProductPrice(item);
          let priceOfAll = productPrice.retail * item.cartQuantity || 0;

          if (
            item.bundle?.bundleType === 'DISCOUNT'
            && item.bundle?.productType === 'PROMOTION'
          ) {
            const { activateWholesale, mandatoryQuantifier } = getItemQuantityForBundle(item.bundle._id, item.sellerId);
            if (activateWholesale) {
              const { mandatory } = item.bundle;

              const productPriceWithoutPromotion = getProductPrice(
                item,
                null,
                null,
                true,
              );
              const { maxDiscountedQty } = item.bundle;
              const retailPriceWithoutPromotion = productPriceWithoutPromotion.retail;
              if (mandatory) {
                const mandatoryQuantity = mandatoryQuantifier * maxDiscountedQty;
                if (item.cartQuantity <= mandatoryQuantity) {
                  priceOfAll = retailPriceWithoutPromotion * item.cartQuantity;
                } else {
                  priceOfAll = retailPriceWithoutPromotion * mandatoryQuantity
                    + productPrice.retail
                      * (item.cartQuantity - mandatoryQuantity);
                }
              } else if (item.cartQuantity <= maxDiscountedQty) {
                priceOfAll = retailPriceWithoutPromotion * item.cartQuantity;
              } else {
                priceOfAll = retailPriceWithoutPromotion * maxDiscountedQty
                  + productPrice.retail * (item.cartQuantity - maxDiscountedQty);
              }
            }
          }

          grossTotal = +grossTotal + +priceOfAll;
        }
      });

      return grossTotal;
    },
    [cartItems],
  );

  const getTotalPriceOfBundle = useCallback(
    (bundleId) => {
      const allCartItems = getCartItems();
      let actualTotal = 0;
      allCartItems.forEach((item) => {
        if (
          item.bundle?._id
          && `${item.bundle._id}` === `${bundleId}`
          && item.stockValue > 0
        ) {
          const productPrice = getProductPrice(item);
          let priceOfAll = productPrice.price * item.cartQuantity || 0;
          if (
            item.bundle?.bundleType === 'DISCOUNT'
            && item.bundle?.productType === 'PROMOTION'
          ) {
            const { activateWholesale, mandatoryQuantifier } = getItemQuantityForBundle(item.bundle._id, item.sellerId);
            if (activateWholesale) {
              const { maxDiscountedQty, mandatory } = item.bundle;

              const productPriceWithoutPromotion = getProductPrice(
                item,
                null,
                null,
                true,
              );
              const promotedPrice = calcPromotionPrice(
                productPriceWithoutPromotion.retail,
                item.bundle,
              );
              if (mandatory) {
                const mandatoryQuantity = mandatoryQuantifier * maxDiscountedQty;
                if (item.cartQuantity <= mandatoryQuantity) {
                  priceOfAll = promotedPrice * item.cartQuantity;
                } else {
                  priceOfAll = promotedPrice * mandatoryQuantity
                    + productPrice.price
                      * (item.cartQuantity - mandatoryQuantity);
                }
              } else if (item.cartQuantity <= maxDiscountedQty) {
                priceOfAll = promotedPrice * item.cartQuantity;
              } else {
                priceOfAll = promotedPrice * maxDiscountedQty
                  + productPrice.price * (item.cartQuantity - maxDiscountedQty);
              }
            }
          }

          actualTotal = +actualTotal + +priceOfAll;
        }
      });
      return actualTotal;
    },
    [cartItems],
  );

  useEffect(() => {
    if (Object.values(mandatoryPromotionalProducts).length > 0) {
      Object.values(mandatoryPromotionalProducts).forEach((item) => {
        const {
          _id: bundleId,
          sellerId,
          sellerName,
          maxDiscountedQty,
        } = item.bundle;
        const cartQuantity = getItemQuantityBySeller(sellerId, item._id);

        const { mandatoryQuantifier } = getItemQuantityForBundle(
          bundleId,
          sellerId,
        );
        const maxQty = getProductMaxQty(item);
        const newMaxDiscountedQty = mandatoryQuantifier * maxDiscountedQty;
        const mandatoryQuantity = Math.min(
          maxQty,
          item.stockValue,
          newMaxDiscountedQty,
        );

        if (cartQuantity < mandatoryQuantity) {
          updateProductQuantity(item, mandatoryQuantity, sellerId, sellerName);
        }
      });
    }
  }, [bundlesInCart, mandatoryPromotionalProducts]);

  return (
    <BundleContext.Provider
      value={{
        fetchBundleDetails,
        bundles,
        bundleLoading,
        promotionalProducts,
        getGrossTotalOfBundle,
        getTotalPriceOfBundle,
      }}
    >
      {children}
    </BundleContext.Provider>
  );
}
