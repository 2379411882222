// Core Packages
import { useState, useEffect } from 'react';
import classnames from 'classnames';

// Custom Components
import StyledError from '@/components/Error';
import StyledLabel from '@/components/Label';

// Styles
import styles from './style.module.css';

export default function Textarea({
  className,
  label,
  required = false,
  placeholder = 'Type something',
  value = '',
  maxLength = 500,
  rows = 5,
  onChange = () => {},
  disabled = false,
}) {
  const [inputValue, setValue] = useState(value);
  const [error, setError] = useState(null);
  const containerClasses = classnames('relative', className);

  useEffect(() => setValue(value), [value]);

  const onChangeHandler = (event) => {
    const { value } = event.target;
    const trimmedVal = value.substring(0, maxLength);
    setValue(trimmedVal);
    onChange(trimmedVal);
    if (required && inputValue) {
      setError(null);
    }
  };

  const onBlurHandler = () => {
    if (required && !inputValue) {
      setError('This field is required.');
    }
  };

  return (
    <div className={containerClasses}>
      {!!label && (
        <StyledLabel label={label} htmlFor={label} required={required} />
      )}
      <div className="relative">
        <textarea
          name={label}
          className={styles.textarea}
          rows={rows}
          value={inputValue}
          required={required}
          placeholder={placeholder}
          maxLength={maxLength}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          disabled={disabled}
        />
        <p className={styles.helperText}>{`${inputValue.length} / ${maxLength}`}</p>
      </div>
      {error && (
        <StyledError
          className={styles.error}
          message={error}
        />
      )}
    </div>
  );
}
