import { useState, createContext } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MiniNotification from '@/components/MiniNotification';

export const NotificationContext = createContext({});

export default function NotificationProvider({ children }) {
  const DEFAULT_NOTIFICATION_PROPS = {
    elevation: 6,
    variant: 'filled',
    severity: 'error',
    message: 'Demo Notification',
    anchorOrigin: { vertical: 'top', horizontal: 'center' },
    autoHideDuration: 5000,
  };

  const [miniNotificationVisible, setMiniNotificationVisible] = useState(false);
  const [miniNotificationDuration, setMiniNotificationDuration] = useState(3000);
  const [isNotificationVisible, setNotificationVisibility] = useState(false);
  const [miniNotificationText, setMiniNotificationText] = useState('');
  const [miniNotificationVariant, setMiniNotificationVariant] = useState('');
  const [miniNotificationIsError, setMiniNotificationIsError] = useState(false);
  const [notificationProps, setNotificationProps] = useState(
    DEFAULT_NOTIFICATION_PROPS,
  );

  const hideNotification = () => setNotificationVisibility(false);

  const showMiniNotification = ({
    duration,
    text,
    isError,
    variant = null,
  }) => {
    setMiniNotificationText(text);
    setMiniNotificationVisible(true);
    setMiniNotificationIsError(!!isError);
    if (duration) {
      setMiniNotificationDuration(duration);
    }
    setMiniNotificationVariant(variant);
  };

  const showNotification = (providedProps) => {
    setNotificationProps({
      ...DEFAULT_NOTIFICATION_PROPS,
      onClose: hideNotification,
      ...providedProps,
    });
    setNotificationVisibility(true);
  };

  return (
    <NotificationContext.Provider
      value={{ showNotification, hideNotification, showMiniNotification }}
    >
      <Snackbar
        open={isNotificationVisible}
        onClose={hideNotification}
        anchorOrigin={notificationProps.anchorOrigin}
        autoHideDuration={notificationProps.autoHideDuration}
      >
        <MuiAlert
          elevation={notificationProps.elevation}
          severity={notificationProps.severity}
          variant={notificationProps.variant}
        >
          {notificationProps.message}
        </MuiAlert>
      </Snackbar>

      {children}

      <MiniNotification
        visible={miniNotificationVisible}
        setVisible={setMiniNotificationVisible}
        duration={miniNotificationDuration}
        text={miniNotificationText}
        isError={miniNotificationIsError}
        variant={miniNotificationVariant}
      />
    </NotificationContext.Provider>
  );
}
