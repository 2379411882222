// Core Packages
import { forwardRef } from 'react';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';

// Styles
import classNames from 'classnames';
import styles from './style.module.css';

const Transition = forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const ConfirmBox = ({
  confirmTitle,
  confirmText,
  confirmOk: okText,
  successFn,
  isConfirmBox,
  closeConfirmBox,
  className,
}) => {
  const confirmed = () => {
    closeConfirmBox();
    successFn();
  };

  return (
    <Dialog
      className={classNames(className, styles.mainDialog)}
      open={isConfirmBox}
      transition={Transition}
      keepMounted
      onClose={() => closeConfirmBox()}
      // PaperComponent={className}
    >
      <DialogTitle disableTypography className={styles.modalHeader}>
        <h4 className={styles.modalTitle}>{confirmTitle}</h4>
        <Close
          className={styles.modalCloseButton}
          onClick={() => closeConfirmBox()}
        />
      </DialogTitle>
      <DialogContent className={styles.modalBody}>
        <div>{confirmText}</div>
      </DialogContent>
      <DialogActions>
        <Button
          className={styles.modalAction}
          color="primary"
          onClick={() => confirmed()}
        >
          {okText || 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmBox;
