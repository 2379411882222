/* eslint-disable indent */
// Core
import { useState, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import App from 'next/app';
import { ApolloProvider } from '@apollo/client';
import classnames from 'classnames';

// Material
import { ThemeProvider } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

// Custom Components
import BottomNavigationBar from '@/components/BottomNavigationBar';
import ViewCart from '@/components/ViewCart';
import ConfirmBox from '@/components/ConfirmBox';
// import EmptyScreen from '@/containers/EmptyScreen';
import LoaderDialog from '@/components/LoaderDialog';
import CookieConsent from '@/components/CookieConsent';

// utils
import theme from 'src/mui-theme';
import { ErrorBoundary } from 'react-error-boundary';
import client, { getAuthCookie, configureApolloClientSSR } from 'apollo-client';
import ErrorFallback from 'ErrorFallback';

// Contexts
import GQLProvider, { GqlContext } from '@/contexts/gql';
import CartProvider, { CartContext } from '@/contexts/cart';
import BundleProvider, { BundleContext } from '@/contexts/bundle';
import NotificationProvider, {
  NotificationContext,
} from '@/contexts/Notification';
import AuthUserProvider from '@/contexts/authUser';
import CommonProvider, { CommonContext } from '@/contexts/common';
import OrderProvider from '@/contexts/order';

// Translation
import Languages from '@/services/translation';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getCookie, removeCookies } from '@/utils/helper';

// Styles
import 'src/index.css';
import 'src/global.css';
import 'src/overrides.css';
import 'src/slick-carousel.css';
import { GET_ALL_COUNTRIES } from '@/graphql/countries';
import SectorProvider from '@/contexts/sector';

const languagesList = Object.keys(Languages);
const preferredLang = getCookie('language') || languagesList[0];
i18n.use(initReactI18next).init({
  resources: {
    english: {
      translation: Languages.english,
    },
    igbo: {
      translation: Languages.igbo,
    },
    yoruba: {
      translation: Languages.yoruba,
    },
    hausa: {
      translation: Languages.hausa,
    },
  },
  lng: preferredLang,
  fallbackLng: languagesList[0],

  interpolation: {
    escapeValue: false,
  },
});
const showBottomNav = (path) => {
  const rootRoutes = [
    '/',
    '/cart',
    '/account',
    '/fallback',
    '/[variant]/[category]/[subCategory]/[productId]',
  ];
  const nestedRoutes = ['/category', '/search'];
  return (
    rootRoutes.concat(nestedRoutes).includes(path)
    || nestedRoutes.some((route) => path.startsWith(route))
  );
};

function CustomApp({
  Component,
  pageProps,
  hasAuthCookie,
  allCountries,
  baseUrl,
  activeCountry,
  isVeedez,
}) {
  const [contextLoader, setContextLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSmallDevice, setIsSmallDevice] = useState(false);
  const { pathname, events } = useRouter();
  const hasBottomNav = useMemo(() => showBottomNav(pathname), [pathname]);
  const [veedez, setVeedez] = useState(false);
  const pageClasses = classnames('flex-1 w-100 overflow-hidden relative', {
    'pad-bottom': hasBottomNav && isSmallDevice,
  });

  const resizeHTMLHeight = () => {
    const htmlElement = document.querySelector('html');
    htmlElement.style.height = '100%';
    // eslint-disable-next-line no-restricted-globals
    setIsSmallDevice(screen.width < 769);
  };

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  });

  useEffect(() => {
    // Resize HTML on window resize
    resizeHTMLHeight();
    window.addEventListener('resize', resizeHTMLHeight);
    if (isVeedez) {
      console.log('in');
      sessionStorage.setItem('veedez', 'true');
      setVeedez(isVeedez);
    }
    return () => {
      window.removeEventListener('resize', resizeHTMLHeight);
    };
  }, []);

  // useEffect(() => {
  //   // post message veedezif (typeof window !== 'undefined') {
  //   window.addEventListener(
  //     'message',
  //     (event) => {
  //       if (event.origin !== 'http://localhost:3000') return;
  //       console.log(event);
  //     },
  //     false,
  //   );
  //   return () => {
  //     window.removeEventListener('message', () => {});
  //   };
  // }, []);
  useEffect(() => {
    removeCookies([{ name: 'referral_code', path: '/' }]);

    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);

    events.on('routeChangeStart', handleStart);
    events.on('routeChangeComplete', handleComplete);
    events.on('routeChangeError', handleComplete);

    return () => {
      events.off('routeChangeStart', handleStart);
      events.off('routeChangeComplete', handleComplete);
      events.off('routeChangeError', handleComplete);
    };
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CookieConsent />
          <AuthUserProvider
            setContextLoader={setContextLoader}
            hasAuthCookie={hasAuthCookie}
          >
            {contextLoader && hasAuthCookie ? (
              <div
                className="w-100 col j-center a-center"
                style={{ minHeight: '100vh' }}
              >
                <LoaderDialog loading={contextLoader} />
                <p
                  style={{ position: 'relative', top: '48px', display: 'flex' }}
                >
                  Please wait...
                </p>
              </div>
            ) : (
              <CommonProvider>
                <SectorProvider>
                  <GQLProvider
                    allCountries={allCountries}
                    baseUrl={baseUrl}
                    activeCountry={activeCountry}
                    veedez={veedez}
                  >
                    <NotificationProvider>
                      <CartProvider>
                        <BundleProvider>
                          <GqlContext.Consumer>
                            {(gqlProps) => (
                              <CartContext.Consumer>
                                {(cartProps) => (
                                  <BundleContext.Consumer>
                                    {(bundleProps) => (
                                      <CommonContext.Consumer>
                                        {(commonProps) => (
                                          <NotificationContext.Consumer>
                                            {(notificationProps) => (
                                              <>
                                                {/* <div className="large-screen"> */}
                                                {/* <div>
                                        <EmptyScreen />
                                      </div> */}
                                                <div className="h-100 col j-start a-stretch">
                                                  {(loading
                                                    || cartProps.loading) && (
                                                      <div className="progress">
                                                        <LinearProgress />
                                                      </div>
                                                    )}
                                                  <div className={pageClasses}>
                                                    <OrderProvider>
                                                      <Component
                                                        {...pageProps}
                                                        {...cartProps}
                                                        {...notificationProps}
                                                        {...gqlProps}
                                                        {...bundleProps}
                                                        isSmallDevice={
                                                          isSmallDevice
                                                        }
                                                      />
                                                    </OrderProvider>
                                                    <ViewCart
                                                      currentPath={pathname}
                                                      hasBottomNav={hasBottomNav}
                                                      {...cartProps}
                                                      {...notificationProps}
                                                      {...gqlProps}
                                                    />
                                                  </div>
                                                  {hasBottomNav && (
                                                    <BottomNavigationBar
                                                      currentPath={pathname}
                                                      {...cartProps}
                                                      {...notificationProps}
                                                      {...gqlProps}
                                                    />
                                                  )}
                                                  <ConfirmBox
                                                    {...commonProps}
                                                    {...notificationProps}
                                                    {...gqlProps}
                                                  />
                                                </div>
                                              </>
                                            )}
                                          </NotificationContext.Consumer>
                                        )}
                                      </CommonContext.Consumer>
                                    )}
                                  </BundleContext.Consumer>
                                )}
                              </CartContext.Consumer>
                            )}
                          </GqlContext.Consumer>
                        </BundleProvider>
                      </CartProvider>
                    </NotificationProvider>
                  </GQLProvider>
                </SectorProvider>
              </CommonProvider>
            )}
          </AuthUserProvider>
        </ThemeProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

CustomApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const authCookie = getAuthCookie(appContext.ctx);
  const client = configureApolloClientSSR(appContext);
  const { data } = await client.query({
    query: GET_ALL_COUNTRIES,
  });

  const allCountries = data?.getAllCountries || [];
  const defaultCountry = { countryCode: 'NG' };

  let activeCountry = allCountries.find(
    (c) => c.countryCode === defaultCountry.countryCode,
  );

  const isAlerzo = process.env.NEXT_PUBLIC_API_URL.includes('alerzo');
  const isSK = process.env.NEXT_PUBLIC_API_URL.includes('skexpress') || process.env.IS_SK;
  const isVeedez = Object.keys(appContext.router.query).includes('veedez');

  if (isSK) {
    activeCountry = allCountries.find((c) => c.countryCode === 'UG');
  } else if (isAlerzo) {
    activeCountry = allCountries.find((c) => c.countryCode === 'NG');
  }

  return {
    ...appProps,
    hasAuthCookie: !!authCookie,
    allCountries: data?.getAllCountries,
    baseUrl: activeCountry?.baseUrl,
    activeCountry,
    isVeedez,
  };
};

export default CustomApp;
