/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
// Core Packages
/* eslint-disable max-len */
import {
  createContext,
  useCallback,
  useState,
  useEffect,
  useContext,
} from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { AuthUserContext } from '@/contexts/authUser';
import moment from 'moment';

// Utils
import {
  GET_CART_ITEMS,
  UPDATE_CART_ITEMS,
  GET_NOTIFIED_PRODUCTS,
  GET_MIN_ORDER_AMOUNT,
  CHECK_COUPON_VALIDITY,
  GET_CREDIT_LIMIT,
  GET_CART_INFO,
} from '@/graphql/cart';
import { addToCart, removeFromCart, reorderIntended } from '@/utils/analytics';
import { getCookie, calcPromotionPrice } from '@/utils/helper';
import { GET_VERTICALS } from '@/graphql/products';

export const CartContext = createContext({});

export default function CartProvider({ children }) {
  const { authUser, warehouseId } = useContext(AuthUserContext);
  const [userVerticals, setUserVerticals] = useState([]);
  const [cartItems, setCartItems] = useState({});
  const [cartBifurcation, setCartBifurcation] = useState([]);
  const [getCart, { data, loading, called }] = useLazyQuery(GET_CART_ITEMS, {
    fetchPolicy: 'network-only',
  });
  const [getVerticals, { data: verticalInfo }] = useLazyQuery(GET_VERTICALS, {
    fetchPolicy: 'network-only',
  });
  const [getCartInfo, { data: cartInfo }] = useLazyQuery(GET_CART_INFO, {
    fetchPolicy: 'network-only',
  });

  const [updateCartItem, { loading: updating }] = useMutation(UPDATE_CART_ITEMS);
  const [getMinOrderAmount, { data: minOrderAmount }] = useLazyQuery(
    GET_MIN_ORDER_AMOUNT,
    {
      fetchPolicy: 'network-only',
    },
  );
  const [getNotifiedProducts, { data: productsPendingNotification, refetch }] = useLazyQuery(GET_NOTIFIED_PRODUCTS);
  const [
    checkCouponValidity,
    {
      data: couponValidity,
      loading: checkingCouponValidity,
      error: invalidCoupon,
    },
  ] = useLazyQuery(CHECK_COUPON_VALIDITY, {
    fetchPolicy: 'network-only',
  });

  const [getCreditLimit, { data: creditLimit }] = useLazyQuery(
    GET_CREDIT_LIMIT,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [customerInfo, setCustomerInfo] = useState();
  const [appliedCoupon, setAppliedCoupon] = useState({});
  const [validCouponDetails, setValidCouponDetails] = useState(null);
  const [invalidCouponDetails, setInvalidCouponDetails] = useState(null);

  const getCartItems = useCallback(
    (vertical = '') => {
      if (vertical) {
        return Object.values(cartItems).filter(
          (item) => item.vertical === vertical,
        );
      }
      return Object.values(cartItems);
    },
    [cartItems],
  );

  const generateCartID = (prodID, sellerID) => `${prodID}_${sellerID}`;

  const getFirstWithIDInCart = useCallback(
    (productId) => {
      const allItems = getCartItems();
      const matchingItem = allItems.find((i) => i._id === productId);

      return matchingItem;
    },
    [cartItems],
  );

  const getItemQuantityBySeller = useCallback(
    (sellerId, productId) => {
      const cartID = generateCartID(productId, sellerId);

      const qty = cartItems?.[cartID]?.cartQuantity || 0;

      return qty;
    },
    [cartItems],
  );

  /** ****** */
  const getTotalItemQuantityInCart = useCallback(
    (productId) => {
      const cartArray = Object.values(cartItems);
      const qty = cartArray.filter((it) => it._id === productId);
      let total = 0;
      if (qty?.length) {
        total = qty?.reduce((a, b) => ({
          cartQuantity: a.cartQuantity + b.cartQuantity,
        }))?.cartQuantity;
      }
      return total;
      // return cartItems[productId]?.cartQuantity || 0;
    },
    [cartItems],
  );

  const getProductNotification = useCallback(
    (prod) => {
      const results = productsPendingNotification?.getNotifiedProductsByCustomerId;
      if (!results?.length) return false;
      const warehouseCookie = getCookie('warehouse');

      const matchingProduct = results.find(
        (it) => it?.productId === prod._id && it?.warehouseId === warehouseCookie,
      );

      if (matchingProduct) return true;
      return false;
    },
    [productsPendingNotification],
  );

  const doesProductHaveAnyStock = (prod, sellerID) => {
    let stockExists = false;

    if (!sellerID) {
      if (prod.stockValue && prod.stockValue > 0) {
        stockExists = true;
      }
      if (prod.sellers?.length) {
        const anyStockWithSellers = prod.sellers.reduce((a, b) => ({
          stockValue: a.stockValue + b.stockValue,
        }))?.stockValue;

        if (anyStockWithSellers && anyStockWithSellers > 0) {
          stockExists = true;
        }
      }
    } else {
      const stock = prod?.sellers?.find(
        (it) => it?.sellerId === sellerID,
      )?.stockValue;
      if (stock && stock > 0) {
        stockExists = true;
      }
    }
    return stockExists;
  };

  const getItemQuantityForBundle = useCallback((bundleId, sellerId) => {
    let bundleCount = 0;
    let minQuantity = 0;
    let activateWholesale = false;

    const filterProductsInBundle = (item) => {
      if (sellerId) {
        return (
          item.bundle?._id
          && `${item.bundle?._id}` === `${bundleId}`
          && `${item.sellerId}` === `${sellerId}`
          && item.bundle.productType === 'BUNDLE'
          && item.stockValue > 0
        );
      }

      return (
        item.bundle?._id
        && `${item.bundle._id}` === `${bundleId}`
        && item.bundle.productType === 'BUNDLE'
        && item.stockValue > 0
      );
    };

    const itemsAsArray = Object.values(cartItems).filter(
      filterProductsInBundle,
    );

    if (itemsAsArray?.length) {
      bundleCount = itemsAsArray.reduce((a, b) => ({
        cartQuantity: a.cartQuantity + b.cartQuantity,
      }))?.cartQuantity;

      minQuantity = itemsAsArray[0].bundle.minimumQuantity;
      activateWholesale = bundleCount >= minQuantity;
    }

    const mandatoryQuantifier = activateWholesale
      ? Math.floor(bundleCount / minQuantity)
      : 1;
    return {
      bundleCount,
      activateWholesale,
      minQuantity,
      mandatoryQuantifier,
    };
  });

  const getProductPrice = useCallback(
    (
      prod,
      bundleSeller,
      getItemQuantityForBundleFn,
      ignorePromotions = false,
    ) => {
      const {
        pricing,
        minWholeSaleQty,
        discountPricing,
        cityWisePricing,
        promotion,
        _id,
        bundle,
        sellers,
      } = prod;
      // const bundle = sellers?.find((s) => s?.bundle?.bundleName)?.bundle;

      const sellerId = bundleSeller || prod.sellerId || null;

      let initialPricing = pricing;
      let initialDiscountPricing = !ignorePromotions ? discountPricing : null;
      let initialMinWholeSaleQty = minWholeSaleQty;

      if (sellerId && sellers?.length) {
        const sellerInfo = sellers.find((it) => it.sellerId === sellerId);

        initialPricing = sellerInfo?.pricing || pricing;
        initialMinWholeSaleQty = sellerInfo?.minWholeSaleQty || minWholeSaleQty;
        initialDiscountPricing = !ignorePromotions
          ? sellerInfo?.discountPricing || discountPricing
          : null;
      }

      const cityId = getCookie('cityId');

      const quantityInCart = getItemQuantityBySeller(sellerId, _id);
      const wholesaleQuantities = [initialMinWholeSaleQty];
      let bundleDiscountActive = false;

      // Handle calculating a promotion Price:

      let cityWholesalePrice = null;
      let cityRetailPrice = null;
      if (cityWisePricing?.length) {
        const matchingCityPrice = cityWisePricing?.length
          ? cityWisePricing.find((ct) => ct.cityId === cityId)
          : null;

        cityWholesalePrice = matchingCityPrice?.pricing?.wholesalePrice
          ? Number(matchingCityPrice?.pricing?.wholesalePrice)
          : null;

        cityRetailPrice = matchingCityPrice?.pricing?.retailPrice
          ? Number(matchingCityPrice?.pricing?.retailPrice)
          : null;

        cityWholesalePrice = isNaN(cityWholesalePrice)
          ? null
          : cityWholesalePrice;
        cityRetailPrice = isNaN(cityRetailPrice) ? null : cityRetailPrice;
      }

      // Determine accurate wholesale and retail prices
      let retail = initialDiscountPricing?.retailPrice
        || cityRetailPrice
        || initialPricing?.retailPrice;
      let wholesale = initialDiscountPricing?.wholesalePrice
        || cityWholesalePrice
        || initialPricing?.wholesalePrice;
      let price = 0;

      const promotionDateValid = (start, end) => {
        const today = moment();
        const startDate = moment(start);
        const endDate = moment(end);

        // omitting the optional third parameter, 'units'
        const result = today.isBetween(startDate, endDate)
          || today.isSame(startDate)
          || today.isSame(endDate);
        return result;
      };

      const promotionActive = promotion?.promotionPlatform === 'ALERZOSHOP_PWA'
        && promotion?.discount
        && promotionDateValid(promotion.startDate, promotion.endDate)
        && promotion.cityId === cityId;

      const promotionWholeSale = promotionActive
        ? calcPromotionPrice(wholesale, promotion)
        : false;
      const promotionRetail = promotionActive
        ? calcPromotionPrice(retail, promotion)
        : false;

      if (promotionActive) {
        const matchingCityPrice = cityWisePricing?.length
          ? cityWisePricing.find((ct) => ct.cityId === cityId)
          : null;

        retail = Math.min(
          ...[
            promotionRetail,
            initialDiscountPricing?.retailPrice,
            matchingCityPrice?.pricing?.retailPrice,
            initialPricing.retailPrice,
          ].filter((p) => p !== undefined && p !== null && p !== 0),
        );
        wholesale = Math.min(
          ...[
            promotionWholeSale,
            initialDiscountPricing?.wholesalePrice,
            matchingCityPrice?.pricing?.wholesalePrice,
            initialPricing.wholesalePrice,
          ].filter((p) => p !== undefined && p !== null && p !== 0),
        );
      }

      const shouldCheckForBundle = !!bundle?._id;

      if (shouldCheckForBundle && bundle.bundleType !== 'DISCOUNT') {
        const bundleCartStatus = getItemQuantityForBundleFn
          ? getItemQuantityForBundleFn(bundle._id, sellerId)
          : getItemQuantityForBundle(bundle._id, sellerId);
        const { activateWholesale, minQuantity } = bundleCartStatus;
        wholesaleQuantities.push(minQuantity);
        bundleDiscountActive = activateWholesale;
        price = activateWholesale ? wholesale : retail;
      } else {
        const activateWholesale = quantityInCart >= initialMinWholeSaleQty;
        price = activateWholesale ? wholesale : retail;
      }

      const minDiscountQty = Math.min(
        ...wholesaleQuantities.filter(
          (p) => p !== undefined && p !== null && p !== 0,
        ),
      );

      const wholesaleActive = bundleDiscountActive || quantityInCart >= minDiscountQty;

      price = wholesaleActive ? wholesale : retail;

      return {
        price,
        retail,
        wholesale,
        wholesaleActive,
        minDiscountQty,
        // maxQuantity,
      };
    },
    [cartItems],
  );

  const getBundleSavingsOfCart = useCallback(
    (cartItems) => {
      let allCartItems = getCartItems();
      if (cartItems) {
        allCartItems = cartItems;
      }

      let bundleSavings = 0;

      allCartItems.forEach((it) => {
        // product price is without promotion
        const productPriceWithoutPromotion = getProductPrice(
          it,
          null,
          null,
          true,
        );
        const retailPriceWithoutPromotion = productPriceWithoutPromotion.retail;
        if (
          it.bundle?.bundleType === 'DISCOUNT'
          && it.bundle?.productType === 'PROMOTION'
        ) {
          const { discount } = it.bundle || 0;
          const { activateWholesale, mandatoryQuantifier } = getItemQuantityForBundle(it.bundle._id, it.sellerId);
          if (activateWholesale && !!discount) {
            const { maxDiscountedQty, mandatory } = it.bundle;
            const promotedPrice = calcPromotionPrice(
              retailPriceWithoutPromotion,
              it.bundle,
            );
            if (mandatory) {
              const mandatoryQuantity = mandatoryQuantifier * maxDiscountedQty;
              if (it.cartQuantity <= mandatoryQuantity) {
                bundleSavings
                  += (retailPriceWithoutPromotion - promotedPrice)
                  * it.cartQuantity;
              } else {
                bundleSavings
                  += (retailPriceWithoutPromotion - promotedPrice)
                  * mandatoryQuantity;
              }
            } else if (it.cartQuantity <= maxDiscountedQty) {
              bundleSavings
                += (retailPriceWithoutPromotion - promotedPrice) * it.cartQuantity;
            } else {
              bundleSavings
                += (retailPriceWithoutPromotion - promotedPrice)
                * maxDiscountedQty;
            }
          }
        }
      });

      return bundleSavings;
    },
    [cartItems],
  );

  const getTotalPriceOfCart = useCallback(
    (cartItems) => {
      let allCartItems = getCartItems();
      if (cartItems) {
        allCartItems = cartItems;
      }
      let actualTotal = 0;

      allCartItems.forEach((it) => {
        if (it.stockValue > 0) {
          const productPrice = getProductPrice(it);
          let priceOfAll = productPrice.price * it.cartQuantity || 0;

          if (
            it.bundle?.bundleType === 'DISCOUNT'
            && it.bundle?.productType === 'PROMOTION'
          ) {
            const { activateWholesale, mandatoryQuantifier } = getItemQuantityForBundle(it.bundle._id, it.sellerId);
            if (activateWholesale) {
              const { maxDiscountedQty, mandatory } = it.bundle;

              const productPriceWithoutPromotion = getProductPrice(
                it,
                null,
                null,
                true,
              );
              const promotedPrice = calcPromotionPrice(
                productPriceWithoutPromotion.retail,
                it.bundle,
              );
              if (mandatory) {
                const mandatoryQuantity = mandatoryQuantifier * maxDiscountedQty;
                if (it.cartQuantity <= mandatoryQuantity) {
                  priceOfAll = promotedPrice * it.cartQuantity;
                } else {
                  priceOfAll = promotedPrice * mandatoryQuantity
                    + productPrice.price * (it.cartQuantity - mandatoryQuantity);
                }
              } else if (it.cartQuantity <= maxDiscountedQty) {
                priceOfAll = promotedPrice * it.cartQuantity;
              } else {
                priceOfAll = promotedPrice * maxDiscountedQty
                  + productPrice.price * (it.cartQuantity - maxDiscountedQty);
              }
            }
          }

          actualTotal = +actualTotal + +priceOfAll;
        }
      });

      if (appliedCoupon && appliedCoupon.discount) {
        actualTotal -= appliedCoupon.discount;
      } else if (validCouponDetails && validCouponDetails.discount) {
        actualTotal -= validCouponDetails.discount;
      }

      return actualTotal;
    },
    [cartItems, validCouponDetails, appliedCoupon],
  );

  const getGrossTotalOfCart = useCallback(
    (cartItems) => {
      let grossTotal = 0;
      let allCartItems = getCartItems();
      if (cartItems) {
        allCartItems = cartItems;
      }
      allCartItems.forEach((it) => {
        if (it.stockValue > 0) {
          const productPrice = getProductPrice(it);
          let priceOfAll = productPrice.retail * it.cartQuantity || 0;

          if (
            it.bundle?.bundleType === 'DISCOUNT'
            && it.bundle?.productType === 'PROMOTION'
          ) {
            const { activateWholesale, mandatoryQuantifier } = getItemQuantityForBundle(it.bundle._id, it.sellerId);
            if (activateWholesale) {
              const { mandatory } = it.bundle;

              const productPriceWithoutPromotion = getProductPrice(
                it,
                null,
                null,
                true,
              );
              const { maxDiscountedQty } = it.bundle;
              const retailPriceWithoutPromotion = productPriceWithoutPromotion.retail;
              if (mandatory) {
                const mandatoryQuantity = mandatoryQuantifier * maxDiscountedQty;
                if (it.cartQuantity <= mandatoryQuantity) {
                  priceOfAll = retailPriceWithoutPromotion * it.cartQuantity;
                } else {
                  priceOfAll = retailPriceWithoutPromotion * mandatoryQuantity
                    + productPrice.retail * (it.cartQuantity - mandatoryQuantity);
                }
              } else if (it.cartQuantity <= maxDiscountedQty) {
                priceOfAll = retailPriceWithoutPromotion * it.cartQuantity;
              } else {
                priceOfAll = retailPriceWithoutPromotion * maxDiscountedQty
                  + productPrice.retail * (it.cartQuantity - maxDiscountedQty);
              }
            }
          }

          grossTotal = +grossTotal + +priceOfAll;
        }
      });

      return grossTotal;
    },
    [cartItems],
  );

  const isAtleastOneProductAvailable = useCallback(
    (vertical = '') => getCartItems(vertical).findIndex((cartItem) => cartItem.stockValue) > -1,
    [cartItems],
  );

  const refetchCart = () => {
    getCart({ variables: { warehouseId } });
  };

  const getProductMaxQty = (prod, sellerId) => {
    const { cityWisePricing } = prod;
    let { stockValue } = prod;
    let { order_qty_max_limit } = prod;

    const productSellerId = sellerId || prod?.sellerId;

    if (productSellerId && prod?.sellers) {
      const sellerDetails = prod?.sellers.find(
        (s) => s.sellerId === productSellerId,
      );
      stockValue = sellerDetails?.stockValue;
      order_qty_max_limit = sellerDetails?.order_qty_max_limit;
    } else if (productSellerId && prod?.seller) {
      const sellerDetails = prod?.seller;
      stockValue = sellerDetails.stockValue;
      order_qty_max_limit = sellerDetails.order_qty_max_limit;
    }
    let cityPrice = null;
    if (cityWisePricing?.length) {
      const cityId = getCookie('cityId');
      const matchingCityPrice = cityWisePricing.find(
        (ct) => ct.cityId === cityId,
      );

      cityPrice = matchingCityPrice?.maximumQuantityPerOrder
        ? Number(matchingCityPrice.maximumQuantityPerOrder)
        : null;
      cityPrice = isNaN(cityPrice) ? null : cityPrice;
    }
    const qtyLimits = [order_qty_max_limit, cityPrice, stockValue];
    const newMax = Math.min(
      ...qtyLimits.filter((p) => p !== undefined && p !== null && p !== 0),
    );

    return newMax || 0;
  };

  const getSellerDetails = (prodID, bundleSellerId = null) => {
    const allCartItems = getCartItems();
    const matchingProductInCart = allCartItems.find((it) => {
      if (bundleSellerId) {
        return it._id === prodID && it.sellerId === bundleSellerId;
      }

      return it._id === prodID;
    });

    if (!matchingProductInCart) return false;
    const { sellerId, sellerName, sellers } = matchingProductInCart;
    return { sellerId, sellerName, sellers };
  };

  const updateProductQuantity = (
    product,
    quantity,
    sellerId,
    sellerName,
    pageSource = '',
  ) => {
    // Get the right seller

    const cartID = generateCartID(product._id, sellerId);
    const oldQuantity = cartItems[cartID]?.cartQuantity || 0;

    if (pageSource.length > 0) {
      reorderIntended(
        product._id,
        product.variant,
        product.category,
        product.brand,
        quantity,
        sellerId,
        sellerName,
        pageSource,
        authUser.customerId,
      );
    }

    updateCartItem({
      variables: {
        warehouseId,
        items: [
          {
            productId: product._id,
            quantity,
            ...(sellerId && { sellerId, sellerName }),
          },
        ],
        vertical: product.vertical,
        verticalId: product.verticalId,
      },
    })
      .then((res) => {
        // refetchCart();
        const { updateCart = [] } = res.data;
        const newCartItem = {};
        updateCart.forEach(({
          productId, quantity, sellerId, sellerName,
        }) => {
          const responseID = generateCartID(productId, sellerId);
          if (cartID === responseID && !!quantity) {
            newCartItem[responseID] = {
              ...product,
              cartQuantity: quantity,
              sellerId,
              sellerName,
            };
          }
        });
        if (quantity) {
          setCartItems((prevItems) => ({ ...prevItems, ...newCartItem }));
        } else {
          const cartUpdate = { ...cartItems };
          delete cartUpdate[cartID];
          setCartItems({ ...cartUpdate });
        }

        if (oldQuantity < quantity) {
          const change = quantity - oldQuantity;
          addToCart(
            product._id,
            product.variant,
            product.category,
            product.brand,
            change,
            authUser?.customerId,
          );
        } else {
          const change = oldQuantity - quantity;
          removeFromCart(
            product._id,
            product.variant,
            product.category,
            product.brand,
            change,
            authUser?.customerId,
          );
        }
      })
      .catch(() => setCartItems({ ...cartItems }));
  };

  const removeItemFromCart = (productIds) => {
    const items = productIds.map((id) => ({
      productId: id,
      quantity: 0,
    }));

    updateCartItem({
      variables: { items, warehouseId },
    })
      .then(() => refetchCart())
      .catch(() => setCartItems({ ...cartItems }));
  };

  useEffect(() => {
    if (authUser) {
      const cityId = getCookie('cityId');
      const stateId = getCookie('stateId');
      getCart({ variables: { warehouseId } });

      getMinOrderAmount({
        variables: {
          cityId,
          warehouseId,
          stateId,
        },
      });
      // }

      getNotifiedProducts({
        variables: {
          customerId: authUser.customerId,
        },
      });

      getCreditLimit({
        variables: {
          customerId: authUser.customerId,
        },
      });
    }
  }, [authUser]);

  useEffect(() => {
    if (warehouseId && authUser) {
      getCart({ variables: { warehouseId } });

      const cityId = getCookie('cityId');
      const stateId = getCookie('stateId');
      getMinOrderAmount({
        variables: {
          cityId,
          warehouseId,
          stateId,
        },
      });
    }
  }, [warehouseId, authUser]);

  useEffect(() => {
    if (data) {
      const { customer, cart = [] } = data.getCustomerCart;
      if (customer) {
        setCustomerInfo(customer);
      }

      const newCartItems = {};

      cart.forEach(({
        product, quantity, sellerId, sellerName,
      }) => {
        const cartID = generateCartID(product._id, sellerId);
        newCartItems[cartID] = {
          ...product,
          cartQuantity: quantity,
          sellerId,
          sellerName,
        };
      });
      setCartItems(newCartItems);
    }

    if (!authUser) return;
    getNotifiedProducts({
      variables: {
        customerId: authUser.customerId,
      },
    });
  }, [data]);

  useEffect(() => {
    if (!authUser) return;
    getCartInfo();
  }, [cartItems, authUser]);

  useEffect(() => {
    if (!cartInfo) return;
    const cartQuantityPerSector = cartInfo.getCustomerCartInfo;
    setCartBifurcation(cartQuantityPerSector);
  }, [cartInfo]);

  useEffect(() => {
    if (invalidCoupon && invalidCoupon.message) {
      setInvalidCouponDetails(invalidCoupon.message);
    } else if (
      couponValidity
      && couponValidity.checkCouponValidity
      && !couponValidity.checkCouponValidity.valid
    ) {
      setInvalidCouponDetails('Invalid coupon!!');
    }
  }, [invalidCoupon, couponValidity]);

  useEffect(() => {
    if (
      couponValidity
      && couponValidity.checkCouponValidity
      && couponValidity.checkCouponValidity.valid
    ) {
      const { discount, isReferCode } = couponValidity.checkCouponValidity;
      const { couponName, couponCode, couponDescription } = couponValidity.checkCouponValidity.coupon;
      setValidCouponDetails({
        discount,
        couponName: isReferCode ? 'REFERRAL' : couponName,
        couponCode,
        couponDescription,
        isReferCode,
      });
    }
  }, [couponValidity]);

  useEffect(() => {
    getVerticals({
      variables: { populateAll: true, populateSubcategories: false },
    });
  }, []);

  useEffect(() => {
    if (verticalInfo?.fetchVerticals?.nodes) {
      const allVerticals = [...verticalInfo?.fetchVerticals?.nodes]?.sort(
        (a, b) => a.sequenceNo - b.sequenceNo,
      );
      if (authUser) {
        // eslint-disable-next-line max-len
        const permittedVerticals = allVerticals.filter(
          (v) => !!authUser.verticals.find((vert) => v.verticalName === vert),
        );
        setUserVerticals(permittedVerticals);
      } else {
        setUserVerticals(allVerticals);
      }
    }
  }, [verticalInfo, authUser]);

  const resetCoupons = () => {
    setInvalidCouponDetails(null);
    setValidCouponDetails(null);
  };

  const validateCoupon = (couponCode, orderTotal) => {
    resetCoupons();
    checkCouponValidity({
      variables: {
        couponCode,
        orderTotal,
      },
    });
  };

  return (
    <CartContext.Provider
      value={{
        userVerticals,
        cartItems,
        cartBifurcation,
        customerInfo,
        loading: loading || updating,
        loadingCartItems: loading || !called,
        minOrderAmount: minOrderAmount?.getMinimumOrderAmountByCity,
        availableLimit: creditLimit?.getCreditLimit?.availableCreditLimit,
        totalLimit: creditLimit?.getCreditLimit?.totalCreditLimit,
        refetch: () => {
          console.log('Re-Fetching notified products now');
          refetch();
        },
        refetchCart,
        getCartItems,
        updateProductQuantity,
        removeItemFromCart,
        getItemQuantityBySeller,
        getTotalPriceOfCart,
        getGrossTotalOfCart,
        getBundleSavingsOfCart,
        getItemQuantityForBundle,
        isAtleastOneProductAvailable,
        getProductPrice,
        getProductMaxQty,
        getProductNotification,
        productsPendingNotification,
        getSellerDetails,
        getTotalItemQuantityInCart,
        doesProductHaveAnyStock,
        getFirstWithIDInCart,
        validateCoupon,
        checkingCouponValidity,
        setAppliedCoupon,
        appliedCoupon,
        validCouponDetails,
        invalidCouponDetails,
        resetCoupons,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
