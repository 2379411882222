import { createMuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Avenir"',
      'Roboto',
      'Helvetica',
      'Arial',
      '"sans-serif"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#00559e',
    },
    secondary: {
      main: '#000000',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#ffffff',
    },
  },
});

export default theme;
