/* eslint-disable no-confusing-arrow */
import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './style.module.css';

const LoaderDialog = ({ loading }) => {
  const [progress, setProgress] = useState(0);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (!loading && timer) {
      clearInterval(timer);
    } else {
      setTimer(
        setInterval(() => {
          setProgress((prevProgress) => prevProgress >= 100 ? 0 : prevProgress + 10);
        }, 700),
      );
    }
    return () => {
      clearInterval(timer);
      setProgress(0);
      // setTimer(null);
    };
  }, [loading]);

  return loading ? (
    <div className={styles.container}>
      <CircularProgress variant="indeterminate" value={progress} size={20} />
    </div>
  ) : null;
};

export default LoaderDialog;
