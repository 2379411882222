// Core Packages
import { useState, createContext, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
// import { useRouter } from 'next/router';
import Cookies from 'js-cookie';

// utils
import { GET_AUTH_USER } from '@/graphql/auth';
import { GET_STATES_CITIES } from '@/graphql/home';
import { setProperties } from '@/utils/analytics';
import { GET_CUSTOMER_META, GET_NEAREST_WAREHOUSE } from '@/graphql/misc';

// Translation
import Languages, { LanguageShortForms } from '@/services/translation';
import i18n from 'i18next';
import { getCookie, setCookie, setLocationCookies } from '@/utils/helper';

export const AuthUserContext = createContext({});

export default function AuthUserProvider({
  setContextLoader,
  hasAuthCookie,
  children,
}) {
  // const { events } = useRouter();
  const languagesList = Object.keys(Languages);
  const isAlerzo = process.env.NEXT_PUBLIC_API_URL.includes('alerzo');
  const isSK = process.env.NEXT_PUBLIC_API_URL.includes('skexpress');
  const [authUserLoading, setAuthUserLoading] = useState(true);
  const [authUser, setAuthUser] = useState(undefined);
  const { data: stateAndCitiesData } = useQuery(GET_STATES_CITIES);
  const [userLang, setUserLang] = useState(languagesList[0]);
  const [usedWarehouseId, setUsedWarehouseId] = useState(null);
  const [fetchAuthUser, { data, loading, refetch }] = useLazyQuery(
    GET_AUTH_USER,
    {
      fetchPolicy: 'network-only',
    },
  );
  const [getSearchResults, { data: nearestWarehouseData }] = useLazyQuery(
    GET_NEAREST_WAREHOUSE,
  );
  const [getCustomerMeta, { data: metaData }] = useLazyQuery(
    GET_CUSTOMER_META,
    {
      fetchPolicy: 'network-only',
    },
  );
  const reloadAuthUserIfEmpty = () => {
    if (!authUser && hasAuthCookie) {
      setAuthUserLoading(true);
      setContextLoader(true);
      console.log('calling auth user from auth context');
      fetchAuthUser();
    }
  };

  const updateCustomer = (customer) => {
    const updatedCustomerInfo = JSON.parse(JSON.stringify(customer));

    if (
      stateAndCitiesData
      && updatedCustomerInfo
      && Array.isArray(updatedCustomerInfo.Stores)
    ) {
      const stateAndCities = stateAndCitiesData.getStateAndCities;
      const storeCity = updatedCustomerInfo.Stores[0].city;

      if (Array.isArray(stateAndCities)) {
        stateAndCities.forEach((state) => {
          state.Cities.forEach((city) => {
            if (city.cityName === storeCity) {
              updatedCustomerInfo.Stores[0].cityId = city.cityId;
            }
          });
        });
      }
    }

    setAuthUser(updatedCustomerInfo);
  };

  useEffect(() => {
    if (data) {
      const customer = data?.getCustomer;
      if (customer) {
        setProperties(
          customer.customerId,
          customer.businessName,
          customer.phoneNo,
        );
        if (isAlerzo && customer.customerId) {
          getCustomerMeta({
            variables: {
              customerId: customer.customerId,
            },
          });
        }

        updateCustomer(customer);
      }
    }
  }, [data]);

  useEffect(() => {
    let preferredLang = metaData?.getCustomerMeta?.preferredLanguage || '';
    if (preferredLang) {
      setCookie('language', preferredLang);
    }
    Object.keys(LanguageShortForms).forEach((element) => {
      if (element.slice(0, 2) === getCookie('language')) {
        preferredLang = element;
      }
    });
    const preferredLanguage = isAlerzo
      ? preferredLang || getCookie('language') || languagesList[0]
      : languagesList[0];
    setUserLang(preferredLanguage);
    i18n.changeLanguage(preferredLanguage);
  }, [metaData]);

  useEffect(() => {
    if (authUser) {
      updateCustomer(authUser);
    }
  }, [stateAndCitiesData]);

  useEffect(() => {
    if (!loading) {
      setAuthUserLoading(false);
      setContextLoader(false);
    }
  }, [loading]);

  useEffect(() => reloadAuthUserIfEmpty(), [authUser, hasAuthCookie]);

  // useEffect(() => {
  //   events.on('routeChangeStart', reloadAuthUserIfEmpty);

  //   return () => {
  //     events.off('routeChangeStart', reloadAuthUserIfEmpty);
  //   };
  // }, [authUser, hasAuthCookie]);

  // const store = useMemo(() => {
  //   if (authUser && Array.isArray(authUser.Stores) && authUser.Stores.length) {
  //     const store = authUser.Stores[0];
  //     return store;
  //   }
  //   return {};
  // }, [authUser]);

  useEffect(() => {
    const storedId = Cookies.get('warehouse');
    if (storedId) {
      setUsedWarehouseId(storedId);
    }
  }, [authUser]);

  useEffect(() => {
    const storedId = Cookies.get('warehouse');
    if (storedId) {
      return setUsedWarehouseId(storedId);
    }
    if (authUser && !usedWarehouseId) {
      let lat = Number(Cookies.get('lat'));
      let long = Number(Cookies.get('long'));
      const cityId = authUser?.Stores?.[0]?.cityId;
      if (isSK && (!lat || !long)) {
        lat = authUser?.Stores?.[0]?.lat;
        long = authUser?.Stores?.[0]?.long;
        const stateId = authUser?.Stores?.[0]?.stateId;
        const cityName = authUser?.Stores?.[0]?.city;
        const stateName = authUser?.Stores?.[0]?.state;
        setLocationCookies({
          lat,
          long,
          cityId,
          customerId: authUser?.customerId,
          stateId,
          cityName,
          stateName,
        });
      } else if (
        isAlerzo
        && (!lat || !long)
        && stateAndCitiesData?.getStateAndCities
      ) {
        const stateId = authUser?.Stores?.[0]?.stateId;
        const landmarkId = authUser?.Stores?.[0]?.landmarkId;

        const stateAndCities = stateAndCitiesData?.getStateAndCities;

        const matchingState = stateAndCities.find((s) => s.stateId === stateId) || '';
        const matchingCity = matchingState?.Cities?.find((c) => c.cityId === cityId) || '';
        const landmark = matchingCity?.Landmarks?.find((l) => l.landmarkId === landmarkId)
          || '';

        lat = landmark?.lat;
        long = landmark?.long;

        // newly set
        const cityName = authUser?.Stores?.[0]?.city;
        const stateName = authUser?.Stores?.[0]?.state;

        // edge case if lat long are null
        if ((lat || long) === null) {
          const { warehouseId } = landmark;

          setLocationCookies({
            warehouse: warehouseId,
            customerId: authUser?.customerId,
            cityId,
            stateId,
            landmarkId,
            cityName,
            stateName,
            landmark: landmark?.landmarkName,
          });
          return setUsedWarehouseId(warehouseId);
        }
        console.log(' setting location cookies from auth context');
        setLocationCookies({
          lat,
          long,
          customerId: authUser?.customerId,
          cityId,
          stateId,
          landmarkId,
          cityName,
          stateName,
          landmark: landmark?.landmarkName,
        });

        // remove next 5 lines to select nearest warehouse for alerzo as well
        const { warehouseId } = landmark;
        setLocationCookies({
          warehouse: warehouseId,
        });
        return setUsedWarehouseId(warehouseId);
      }
      if (lat && long && !usedWarehouseId) {
        return getSearchResults({
          variables: {
            lat,
            long,
          },
        });
      }
    }
    return false;
  }, [authUser, stateAndCitiesData]);

  useEffect(() => {
    if (
      !usedWarehouseId
      && nearestWarehouseData?.getNearestWarehouse?.length
      && nearestWarehouseData?.getNearestWarehouse?.[0].warehouseId
    ) {
      const warehouseId = nearestWarehouseData?.getNearestWarehouse?.[0].warehouseId;

      setLocationCookies({
        warehouse: warehouseId,
      });
      setUsedWarehouseId(warehouseId);
    }
  }, [nearestWarehouseData]);

  return (
    <AuthUserContext.Provider
      value={{
        authUser,
        userLang,
        warehouseId: usedWarehouseId,
        loading: authUserLoading,
        fetchAuthUser: () => {
          console.log('Fetching auth user now');
          fetchAuthUser();
        },
        refetch: () => {
          console.log('Re-Fetching auth user now');
          refetch();
        },
        setUserLang: (lang) => {
          console.log('Re-setting language now');
          setUserLang(lang);
        },
      }}
    >
      {children}
    </AuthUserContext.Provider>
  );
}
