/* eslint-disable no-lonely-if */
import Cookie from 'js-cookie';

export const formatAmount = (amount, toWholeNumber) => {
  if (toWholeNumber) {
    const value = Math.round(Number(amount));
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  let formattedAmount = (+amount || 0).toFixed(3).toString();
  if (!formattedAmount.includes('.')) {
    formattedAmount = `${amount}.00`;
  }
  return formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const capitalizeText = (txt) => txt?.toLowerCase().replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase());

export const getLatLong = async (ip) => {
  let latitude = null;
  let longitude = null;
  await fetch(`https://ipapi.co/${ip ? `${ip}/` : ''}latlong`)
    .then((response) => response.text())
    .then((data) => {
      if (data) {
        [latitude = null, longitude = null] = data
          .split(',')
          .map((val) => (Number(val) ? Number(val) : null));
      }
    })
    .catch((err) => console.error('Error while getting lat and long:', err));
  return [latitude, longitude];
};

export const getLatLongGooglePlaces = async () => {
  try {
    const API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
    const url = `https://www.googleapis.com/geolocation/v1/geolocate?key=${API_KEY}`;

    const result = await fetch(url, {
      method: 'POST',
    })
      .then((response) => response.json())
      .catch((err) => console.error('Error while getting lat and long:', err));

    return result;
  } catch (error) {
    console.error(
      'An error ocurred while getting lat and long from getLatLongGooglePlaces:',
      error,
    );
    return false;
  }
};

export const getAddressFromLatLong = async (lat, long) => {
  const API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${API_KEY}`;
  let result = '';
  await fetch(url)
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        result = data.results[0].formatted_address;
      }
    })
    .catch((err) => console.error('Error while getting address:', err));
  return result;
};

export const getLatLongAddrGooglePlaces = async (latAndLng) => {
  const longitude = latAndLng?.longitude;
  const latitude = latAndLng?.latitude;
  try {
    const API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
    let latLngAddr = false;

    let lat = latitude;
    let lng = longitude;

    if (!lat || !lng) {
      const latLngResult = await getLatLongGooglePlaces();

      if (latLngResult?.location) {
        lat = latLngResult.location.lat;
        lng = latLngResult.location.lng;
      }
    }
    if (lat && lng) {
      latLngAddr = {
        lat,
        lng,
      };

      const addUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`;
      const addrResult = await fetch(addUrl, {
        method: 'POST',
      })
        .then((response) => response.json())
        .catch((err) => console.error('Error while getting address from lat and long:', err));

      const addrToUse = addrResult?.results?.[0];
      const addr = addrToUse?.formatted_address;
      const location = `${addrToUse?.address_components?.[0]?.long_name} ${addrToUse?.address_components?.[1]?.long_name}`;
      latLngAddr.address = addr;
      latLngAddr.location = location;
    }

    return latLngAddr;
  } catch (error) {
    console.error(
      'An error ocurred while getting lat and long from getLatLongAddrGooglePlaces:',
      error,
    );
    return false;
  }
};

export const decodeURIString = (str) => {
  const isEncoded = (str) => typeof str === 'string' && decodeURIComponent(str) !== str;

  const result = decodeURI(str);

  if (isEncoded(result)) {
    return decodeURIString(result);
  }

  return result;
};

export const setLocationCookies = ({
  cityId,
  cityName,
  stateId,
  stateName,
  landmark,
  landmarkId,
  warehouse,
  customerId,
  lat,
  long,
  address,
}) => {
  const isDev = ['local', 'development'].includes(process.env.NEXT_PUBLIC_ENV);
  if (customerId) {
    Cookie.set('customerId', decodeURIString(customerId), {
      ...(!isDev ? { sameSite: 'none', secure: true } : {}),
    });
  }
  if (cityId) {
    Cookie.set('cityId', decodeURIString(cityId), {
      ...(!isDev ? { sameSite: 'none', secure: true } : {}),
    });
  }
  if (cityName) {
    Cookie.set('cityName', decodeURIString(cityName), {
      ...(!isDev ? { sameSite: 'none', secure: true } : {}),
    });
  }
  if (stateId) {
    Cookie.set('stateId', decodeURIString(stateId), {
      ...(!isDev ? { sameSite: 'none', secure: true } : {}),
    });
  }
  if (stateName) {
    Cookie.set('stateName', decodeURIString(stateName), {
      ...(!isDev ? { sameSite: 'none', secure: true } : {}),
    });
  }
  if (landmark) {
    Cookie.set('landmark', decodeURIString(landmark), {
      ...(!isDev ? { sameSite: 'none', secure: true } : {}),
    });
  }
  if (landmarkId) {
    Cookie.set('landmarkId', decodeURIString(landmarkId), {
      ...(!isDev ? { sameSite: 'none', secure: true } : {}),
    });
  }
  if (warehouse) {
    Cookie.set('warehouse', decodeURIString(warehouse), {
      ...(!isDev ? { sameSite: 'none', secure: true } : {}),
    });
  }
  if (lat) {
    Cookie.set('lat', decodeURIString(lat), {
      ...(!isDev ? { sameSite: 'none', secure: true } : {}),
    });
  }
  if (long) {
    Cookie.set('long', decodeURIString(long), {
      ...(!isDev ? { sameSite: 'none', secure: true } : {}),
    });
  }
  if (address) {
    Cookie.set('address', decodeURIString(address), {
      ...(!isDev ? { sameSite: 'none', secure: true } : {}),
    });
  }
};

export const setCookie = (name, value) => {
  const isDev = ['local', 'development'].includes(process.env.NEXT_PUBLIC_ENV);
  Cookie.set(name, decodeURIString(value), {
    ...(!isDev ? { sameSite: 'none', secure: true } : {}),
  });
};

export const removeCookies = (cookieArray) => {
  const _domain1 = window.location.hostname;

  const domain2Setup = `${window.location.hostname}`.split('.');
  domain2Setup.shift();

  const _domain2 = domain2Setup.join('.');
  const domains = [_domain1, _domain2];
  for (let i = 0; i < cookieArray?.length; i += 1) {
    const currentCookie = cookieArray[i];
    if (currentCookie) {
      Cookie.remove(currentCookie.name, {
        path: currentCookie.path,
        domain: domains[0],
      });

      Cookie.remove(currentCookie.name, {
        path: currentCookie.path,
        domain: domains[1],
      });

      Cookie.remove(currentCookie.name);
    }
  }

  return true;
};

export const getCookie = (cname, cookie) => {
  if (cookie) {
    const name = `${cname}=`;
    const ca = (cookie || '').split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        const result = c.substring(name.length, c.length);
        if (result === 'undefined') return '';
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  if (typeof window !== 'undefined') {
    return Cookie.get(cname) || '';
  }

  return '';
};

export const getDocumentCookie = (cname) => {
  const name = `${cname}=`;
  const cDecoded = decodeURIComponent(document.cookie); // to be careful
  const cArr = (cDecoded || '').split('; ');
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
};

export const getRecentSearches = (key) => {
  try {
    let values = localStorage.getItem(key) || '[]';

    values = JSON.parse(values);

    if (Array.isArray(values)) {
      return values;
    }

    return [];
  } catch (error) {
    console.error('Error while getting from local storage', error);
    return [];
  }
};

export const setRecentSearches = (key, value) => {
  try {
    let values = getRecentSearches(key);

    if (values.indexOf(value) > -1) {
      return;
    }

    values.unshift(value);

    values = JSON.stringify(values);

    localStorage.setItem(key, values);
  } catch (error) {
    console.error('Error while setting local storage', error);
  }
};

export const clearRecentSearches = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error('Error while removing local storage', error);
  }
};

export const clearSessionStorage = (key) => {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    console.error('Error while removing session storage', error);
  }
};

export const clearCacheData = () => {
  try {
    caches?.keys()?.then((names) => {
      names?.forEach((name) => {
        caches?.delete(name);
      });
    });
  } catch (error) {
    console.log('error while deleting cache storage', error);
  }
};

export const isAppleDevice = () => {
  try {
    if (typeof window !== 'undefined') {
      if (!navigator) return false;
      const { platform } = navigator;

      if (
        platform
        && (platform.includes('iPhone')
          || platform.includes('iPad')
          || platform.includes('Mac'))
      ) {
        return true;
      }
    }
    return false;
  } catch (error) {
    console.error('Error while checking for Apple device', error);
    return false;
  }
};

export const makeId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const constructOAuthRedirect = (oAuthUrl, redirectUri) => {
  const query = oAuthUrl.split('?')[1];
  const base = oAuthUrl.split('?')[0];
  const params = {};
  query.split('&').forEach((item) => {
    const itemAsArr = item.split('=');
    // eslint-disable-next-line prefer-destructuring
    params[itemAsArr[0]] = itemAsArr[1];
  });

  params.redirect_uri = redirectUri;

  let newUrl = `${base}?`;
  Object.keys(params).forEach((key, index) => {
    if (index === 0) {
      newUrl += `${key}=${params[key]}`;
    } else {
      newUrl += `&${key}=${params[key]}`;
    }
  });

  return newUrl;
};

export const getAllUrlParams = (url) => {
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
  const obj = {};
  if (queryString) {
    // eslint-disable-next-line prefer-destructuring
    queryString = queryString.split('#')[0];
    // split our query string into its component parts
    const arr = queryString.split('&');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arr.length; i++) {
      const a = arr[i].split('=');
      const paramName = a[0];
      const paramValue = typeof a[1] === 'undefined' ? true : a[1];
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        const key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) {
          obj[key] = [];
        }
        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          const index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }
  return obj;
};

export const validateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const PUBLIC_API_URL = process.env.NEXT_PUBLIC_API_URL;

export const checkSite = (isVeedez) => {
  const isAlerzo = PUBLIC_API_URL.includes('alerzo');
  const isSK = PUBLIC_API_URL.includes('skexpress');
  const isDev = PUBLIC_API_URL.includes('dev');
  return {
    isAlerzo,
    isSK,
    isDev,
    isVeedez,
  };
};

// bestSelling to Best Selling
export const camelCasetoCapitalize = (text) => {
  const result = text?.replace(/([A-Z])/g, ' $1');
  const source = result?.charAt(0).toUpperCase() + result?.slice(1);
  return source;
};
export const seoFriendlyParam = (text) => {
  const result = text
    .trim()
    .replace(/\s+/g, '-')
    .replace(/\//g, '-')
    .toLowerCase();
  return result;
};
export const getRandomItemFromArray = (arr = []) => arr[Math.floor(Math.random() * arr.length)];

export const getNameInitials = (name = '') => {
  const allNames = name.trim().split(' ');
  return allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      return `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
};

export const arraysAreEqual = (arr1, arr2) => arr1?.length === arr2?.length
  && !arr1.map((val) => arr2.includes(val)).includes(false);

export const coordExists = (val) => !Number.isNaN(val) && typeof val === 'number';

export const calcPromotionPrice = (price, promotion) => {
  let result = price;

  if (`${promotion?.discountType}` === 'ABSOLUTE') {
    result = Number(price) - Number(promotion.discount);
  } else if (`${promotion?.discountType}` === 'PERCENTAGE') {
    result = ((100 - Number(promotion.discount)) / 100) * price;
  }

  return Math.round(result);
};

export const getFilteredProductsByAvailability = (products) => {
  const [available, unAvailable] = products.reduce(
    // eslint-disable-next-line max-len
    ([available, unAvailable], product) => ((product?.stockValue && product?.brand && product?.variant) !== null
      ? [[...available, product], unAvailable]
      : [available, [...unAvailable, product]]),
    [[], []],
  );
  return [available, unAvailable];
};

export const imageSrcProvider = (image, imageUrl, src = false) => {
  let result;
  if (
    typeof image === 'object'
    && image != null
    && Object.keys(image).length !== 0
  ) {
    const {
      small, medium, large, original,
    } = image;

    result = src
      ? (large || medium || original || small || '')?.trim()
      : medium?.trim();
  }
  return result || imageUrl?.trim();
};

export function getMinDiffBetweenDates(startDate, endDate) {
  const msInMinute = 60 * 1000;

  return Math.round(Math.abs(endDate - startDate) / msInMinute);
}
