import { logoutCustomer, resetProperties } from '@/utils/analytics';
import {
  removeCookies,
  setCookie,
  clearRecentSearches,
  clearSessionStorage,
} from '@/utils/helper';
import { AUTH_TOKEN } from '@/utils/oauth';
import firebase from '../../firebase';
import { RECENT_SEARCH_NAMES } from '../constants';

export const logout = async (customerId = '') => {
  document.cookie = 'customerId=';
  document.cookie = 'AUTH_ID=';

  clearRecentSearches(RECENT_SEARCH_NAMES.locations);
  clearRecentSearches(RECENT_SEARCH_NAMES.searches);
  clearRecentSearches(RECENT_SEARCH_NAMES.salesIq);
  clearRecentSearches(RECENT_SEARCH_NAMES.salesIqData);
  clearSessionStorage(RECENT_SEARCH_NAMES.salesIqData);

  removeCookies([
    { name: 'customerId', path: '/' },
    { name: 'warehouse', path: '/' },
    { name: 'AUTH_ID', path: '/' },
    { name: 'AUTH_TOKEN', path: '/' },
    { name: 'veedezId', path: '/' },
    { name: 'cityId', path: '/' },
    { name: 'cityName', path: '/' },
    { name: 'stateId', path: '/' },
    { name: 'stateName', path: '/' },
    { name: 'landmarkId', path: '/' },
    { name: 'landmark', path: '/' },
    { name: 'lat', path: '/' },
    { name: 'long', path: '/' },
    { name: 'phone', path: '/' },
    { name: 'sales1.alerzo-_zldp', path: '/' },
    { name: 'sales1.alerzo-_zldt', path: '/' },
    { name: 'activeVertical', path: '/' },
  ]);
  if (customerId.length > 0) {
    logoutCustomer(customerId);
  }
  resetProperties();
  // clearCacheData();
  const logoutUrl = `${process.env.NEXT_PUBLIC_API_URL}/customerLogout`;

  const logoutUrl2 = '/api/auth/logout';
  await fetch(logoutUrl, { method: 'POST', credentials: 'include' });
  await fetch(logoutUrl2, { method: 'POST', credentials: 'include' });

  setTimeout(() => {
    window.location.href = '/logout';
  }, 1000);
};
export const loginWithCustomToken = (customToken) => new Promise((resolve, reject) => {
  firebase
    .auth()
    .signInWithCustomToken(customToken)
    .then(({ user }) => user.getIdToken())
    .then((token) => resolve(token.toString()))
    .catch((err) => {
      console.error('Error while logging in with custom token', err);
      logout().catch(console.error);
      reject(err);
    });
});

export const getCurrentUser = () => new Promise((resolve) => {
  try {
    const user = firebase.auth().currentUser;
    if (user) {
      resolve(user);
    } else {
      const unsubscribeFromAuthListener = firebase
        .auth()
        .onAuthStateChanged((user) => {
          resolve(user);
          unsubscribeFromAuthListener();
        });
    }
  } catch (error) {
    console.error('error while getting current user', error);
  }
});

export const generateIdToken = () => new Promise((resolve, reject) => {
  const processToken = (idToken) => {
    const token = idToken.toString();

    setCookie(AUTH_TOKEN, token);

    resolve(token);
    return token;
  };

  const handleUser = (user) => {
    if (user) {
      user
        .getIdToken()
        .then(processToken)
        .catch((err) => {
          console.error("Error while getting user's id token", err);
          reject(err);
        });
    } else {
      console.error('No user found, while handling user, rejecting');
      // logout();
      reject();
    }
  };

  getCurrentUser()
    .then(handleUser)
    .catch((err) => {
      console.error('Error while getting current user', err);
      reject(err);
    });
}).catch((err) => console.error('Error while generating id token', err));
